import { Skeleton, Stack, Typography } from '@mui/material'
import { Currency, getCurrencySymbol } from '@teamfeedr/utils--money'
import React from 'react'

type Props = {
  description: string
  price: string
  currency: string
  loading?: boolean
  isNegativeValue?: boolean
}

const ChargeRow: React.FC<Props> = ({ description, price, currency, loading, isNegativeValue }) => {
  return (
    <Stack alignItems="center" justifyContent="space-between" direction="row">
      <Typography variant="body2" color="text.secondary">
        {description}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {loading ? (
          <Skeleton width={50} />
        ) : (
          <>
            {isNegativeValue ? '-' : ''}
            {getCurrencySymbol(currency as Currency)}
            {price}
          </>
        )}
      </Typography>
    </Stack>
  )
}

export default ChargeRow
