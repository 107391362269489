import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import Link from '@/components/Link'
import Icon from '@teamfeedr/ui--icon'
import Modal from '@teamfeedr/ui--modal'
import Badge from '@teamfeedr/ui--badge'
import { SPLITS, useFeatureFlag } from '@/helpers/useFeatureFlag'
import { main, mobileMediaQuery } from '@teamfeedr/ui--theme'
import { amountToPriceString } from '@teamfeedr/utils--money'
import { Text, TextExtraSmall, TextSmall } from '@teamfeedr/ui--typography'
import {
  BackLink,
  Badges,
  Content,
  Keywords,
  Details,
  Header,
  Logo,
  Title,
  Veil,
  ModalLogo,
  BoxHeader,
  HandpickedBox,
  SourcingBox,
  MobileImage,
  ImageContainer,
  BlackVeil,
  ModalHeader,
  Info,
} from './index.styles'
import { useMedia } from 'react-use'
import { Currency } from '@/generated/graphql'
import {
  Button,
  Chip,
  Typography,
  Stack,
  useTheme as useMuiTheme,
  Link as MuiLink,
} from '@mui/material'
import { InfoRounded, KeyboardArrowLeftRounded, Warning } from '@mui/icons-material'
import useTenant from '@/hooks/useTenant'
import { VendorExternalUrlData } from '@/generated/gql/graphql'
import { getTenantLinkComponentRule } from '@/helpers/tenants'
import { getImageUrl } from '../image'

type Levels = 'pro' | 'good'

export type Props = {
  images: string[]
  logo?: string | null
  title: string
  keywords?: string | null
  descriptionFull?: string | null
  quote?: string | null
  sourcing?: string | null
  rating?: number | null
  totalRatings?: number | null
  currency: Currency
  minimumOrderValue?: number | null
  minimumOrderValueExTax?: number | null
  minimumNoticePeriod?: number | null
  sustainability?: Levels
  eco?: Levels
  taxAcronym: string | null
  externalUrlData: VendorExternalUrlData
}

const VendorHeader: React.FC<Props> = ({
  images,
  logo,
  title,
  keywords,
  descriptionFull = '',
  quote,
  sourcing,
  rating,
  totalRatings,
  currency,
  minimumOrderValue,
  minimumOrderValueExTax,
  minimumNoticePeriod,
  sustainability,
  eco,
  taxAcronym,
  externalUrlData,
}) => {
  const theme = useTheme()
  const muiTheme = useMuiTheme()
  const tenant = useTenant()
  const allVendorsLink = getTenantLinkComponentRule(
    '/office-catering/vendors',
    tenant.domain,
    false,
  )
  const showFiveStarRating = useFeatureFlag(SPLITS.FIVE_STAR_RATINGS)
  const useMinOrderValueExTax = useFeatureFlag(SPLITS.MIN_ORDER_VALUE_EX_TAX)
  const minOrderVal = useMinOrderValueExTax ? minimumOrderValueExTax : minimumOrderValue
  const [modalOpen, setModalOpen] = useState(false)
  const isMobileScreen = useMedia(mobileMediaQuery, false)

  useEffect(() => {
    document.body.style.overflow = modalOpen ? 'hidden' : 'unset'
  }, [modalOpen])

  const infoUrlData = externalUrlData.data.find((url) => url?.tags.data.includes('default'))
  const infoUrl = infoUrlData?.url || ''
  const infoCopy = infoUrlData?.copy || ''

  return (
    <>
      <Header image={images[0]} backgroundSize="50% auto">
        <Veil>
          {allVendorsLink.displayComponent && (
            <BackLink>
              <Link href={allVendorsLink.uri}>
                <Button size="small" startIcon={<KeyboardArrowLeftRounded />}>
                  Back to all vendors
                </Button>
              </Link>
            </BackLink>
          )}
          <Content>
            {isMobileScreen && (
              <ImageContainer>
                <BlackVeil />
                <MobileImage src={images[0]} alt="vendor" />
              </ImageContainer>
            )}
            <Details>
              {logo && (
                <Logo
                  src={getImageUrl({
                    src: logo,
                    width: 200,
                    quality: 100,
                  })}
                />
              )}
              <Info>
                <Title>{title}</Title>
                <div>
                  {keywords && <Keywords>{keywords}</Keywords>}
                  <Button
                    onClick={() => setModalOpen(true)}
                    size="small"
                    startIcon={<InfoRounded />}
                  >
                    More info
                  </Button>
                </div>
                <Badges>
                  {sustainability && (
                    <Badge
                      title={sustainability.toUpperCase()}
                      text="Sustainability"
                      icon="recycle"
                      variation={sustainability}
                    />
                  )}
                  {eco && (
                    <Badge title={eco.toUpperCase()} text="Eco" icon="leaf" variation={eco} />
                  )}
                  {allVendorsLink.displayComponent && rating && Number(rating) >= 2 ? (
                    <Badge
                      title={`${rating}/${showFiveStarRating ? 5 : 4}`}
                      text={
                        totalRatings
                          ? `(${totalRatings} ${totalRatings > 1 ? 'ratings' : 'rating'})`
                          : ''
                      }
                      icon="star"
                      variation="good"
                    />
                  ) : null}
                  {minOrderVal &&
                    (isMobileScreen ? (
                      <TextExtraSmall style={{ fontWeight: 500 }}>{`${amountToPriceString(
                        currency,
                        minOrderVal,
                        true,
                      )} minimum`}</TextExtraSmall>
                    ) : (
                      <Text style={{ fontWeight: 500 }}>
                        {`${amountToPriceString(currency, minOrderVal, true)} minimum`}{' '}
                        {taxAcronym && useMinOrderValueExTax ? `(ex ${taxAcronym})` : null}
                      </Text>
                    ))}
                </Badges>

                {minimumNoticePeriod && allVendorsLink.displayComponent ? (
                  <Stack spacing={1} direction="row" marginTop={1} alignItems="center">
                    <Chip
                      color="info"
                      label="!"
                      size="small"
                      sx={{ minWidth: '27px', minHeight: '27px' }}
                      variant="outlined"
                    />
                    <Stack alignItems="center" direction="row" flexWrap="wrap">
                      <Typography variant="caption" color="text.disabled">
                        Orders must be placed at least
                      </Typography>
                      <Typography
                        variant="caption"
                        color="info.main"
                        sx={{ margin: '0 0.3rem 0 0.3rem' }}
                      >
                        {minimumNoticePeriod} hours
                      </Typography>
                      <Typography variant="caption" color="text.disabled">
                        in advance.
                      </Typography>
                    </Stack>
                  </Stack>
                ) : null}

                {infoCopy && infoUrl ? (
                  <Stack
                    marginTop={0.5}
                    spacing={1}
                    direction="row"
                    alignContent="center"
                    alignItems="center"
                  >
                    <Warning fontSize="medium" htmlColor={muiTheme.palette.secondary.light} />
                    <Typography variant="body2" color="text.disabled">
                      {infoCopy} [
                      <MuiLink href={infoUrl} target="_blank">
                        Link here
                      </MuiLink>
                      ]
                    </Typography>
                  </Stack>
                ) : null}
              </Info>
            </Details>
          </Content>
        </Veil>
      </Header>

      <div style={{ display: modalOpen ? 'flex' : 'none' }}>
        <Modal.Wrapper
          header={
            <ModalHeader>
              {logo && <ModalLogo src={getImageUrl({ src: logo, width: 50, quality: 100 })} />}
              {title}
            </ModalHeader>
          }
          centerHeader={false}
          body={
            <Modal.Section>
              <TextSmall>{descriptionFull}</TextSmall>
              {quote && (
                <HandpickedBox>
                  <BoxHeader>
                    <Icon icon="heart" fill={main.colors.error} variation="circle" size={17} />
                    Handpicked by {tenant.name}
                  </BoxHeader>
                  {quote}
                </HandpickedBox>
              )}
              {sourcing && (
                <SourcingBox>
                  <BoxHeader>
                    <Icon icon="sourced" fill={theme.colors.primary} variation="circle" size={17} />
                    Sourced Responsibly
                  </BoxHeader>
                  {sourcing}
                </SourcingBox>
              )}
            </Modal.Section>
          }
          images={images.map((image) => getImageUrl({ src: image, width: 600, quality: 100 }))}
          onClose={() => setModalOpen(false)}
        />
      </div>
    </>
  )
}

export default VendorHeader
