import { OptionItem, Maybe, Language, MenuItem } from '@/generated/graphql'
import {
  getMenuItemTranslationFields,
  populateFieldsWithTranslations,
} from '@teamfeedr/utils--translations'

export const getLangFromLocale = (locale: string) => locale.split('-')[0] as Language
export const getCountryFromLocale = (locale: string) => locale.split('-')[1]

type Item = {
  item: MenuItem
  options: {
    itemReference?: string
    optionReference?: string
  }[]
}

export const translateItems = (items: Item[], language: Language) => {
  return items.map((item) => {
    const menuItem = item.item || item
    const translatedItem = populateFieldsWithTranslations(menuItem, language)
    const translatedFields = getMenuItemTranslationFields(translatedItem)
    return {
      ...item,
      ...translatedFields,
      options: item.options.map((option) => {
        const optionMatch = (i: Maybe<OptionItem>) =>
          i?.item?._id === (option.itemReference || option.optionReference)
        const optionCategory = menuItem.options?.find((o) => o?.items?.some(optionMatch))
        if (optionCategory) {
          const optionItem = optionCategory.items?.find(optionMatch)
          if (optionItem?.item) {
            const translatedOptionItem = populateFieldsWithTranslations(optionItem.item, language)
            const transaltedOptionFields = getMenuItemTranslationFields(translatedOptionItem)
            return { ...option, ...transaltedOptionFields }
          }
        }
        return option
      }),
    }
  })
}
