import { FragmentType, graphql, useFragment } from '@/generated/gql'

const useOrderPaymentTypeOrderDataFragment = graphql(`
  fragment UseOrderPaymentType_OrderDataFragment on GmOrder {
    paymentMethod
  }
`)

type Props = {
  orderData: FragmentType<typeof useOrderPaymentTypeOrderDataFragment> | null
}

export const useOrderPaymentType = ({ orderData: orderDataProp }: Props) => {
  const orderData = useFragment(useOrderPaymentTypeOrderDataFragment, orderDataProp)

  return { paymentMethod: orderData?.paymentMethod }
}
