import React from 'react'
import FilterSection from './filter-section'
import { MenuItemFilterSections } from '../../domain/menu-item-filter'
import useTenant from '@/hooks/useTenant'
import useGmFiltersStore from '../../helpers/gmFiltersStore'

type Props = {
  filterSections: MenuItemFilterSections
  asRow: boolean
}

const Filters = ({ filterSections, asRow }: Props) => {
  const tenant = useTenant()
  const { goals, allergens, dietaries } = useGmFiltersStore()
  const hasFiltersSelected = goals.length > 0 || dietaries.length > 0 || allergens.length > 0
  return (
    <>
      <FilterSection
        asRow={asRow}
        type="goals"
        label="Filter by goal"
        filters={filterSections.goals}
        selectedFilters={goals}
        hasFiltersSelected={hasFiltersSelected}
        visibility={true}
      />
      <FilterSection
        asRow={asRow}
        type="dietaries"
        label="Dietary needs"
        filters={filterSections.dietaries}
        selectedFilters={dietaries}
        hasFiltersSelected={hasFiltersSelected}
        visibility={tenant.features?.cateringFilters?.dietary ?? true}
      />
      <FilterSection
        asRow={asRow}
        type="allergens"
        label="Allergens"
        filters={filterSections.allergens}
        selectedFilters={allergens}
        selectedDietaries={dietaries}
        hasFiltersSelected={hasFiltersSelected}
        visibility={tenant.features?.cateringFilters?.allergens ?? true}
      />
    </>
  )
}

export default Filters
