import { useLocale } from '@/components/Link'
import useTenant from '@/hooks/useTenant'
import { useMemo } from 'react'
import { getCountryFromLocale } from '../multiRegion'

const useCharityMeals = (): { enabled: boolean } => {
  const tenant = useTenant()
  const locale = useLocale()

  const showCharityMealsText = useMemo(() => {
    return locale && tenant.features?.charityMeals?.visibility.regions
      ? tenant.features.charityMeals.visibility.regions.includes(getCountryFromLocale(locale))
      : false
  }, [locale, tenant])

  return {
    enabled: showCharityMealsText,
  }
}

export default useCharityMeals
