import styled from 'styled-components'
import { Text } from '@teamfeedr/ui--typography'

export const CaptionLogo = styled.img`
  display: block;
  border-radius: 100%;
  height: 40px;
  width: 40px;
`

export const CaptionText = styled(Text)`
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  margin-right: 40px;
`
