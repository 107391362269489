import styled from 'styled-components'
import { Text } from '@teamfeedr/ui--typography'
import Input from '@teamfeedr/ui--input'
import Datepicker from '@teamfeedr/ui--datepicker'
import { mobileMediaQuery } from '@teamfeedr/ui--theme'
import { DatepickerProps } from './datepicker-input'

export const Wrapper = styled.div`
  display: flex;
  margin: 0;
  padding: 10px 0;

  @media ${mobileMediaQuery} {
    padding: 0 0 10px;
  }
`

export const OrderText = styled(Text)`
  align-self: center;
  width: 135px !important;
`

export const LeftSide = styled(Text)`
  align-self: center;
  width: 100%;
  cursor: pointer;
`

export const RightSide = styled(Text)`
  align-self: center;
  & > div {
    grid-template-columns: 1fr;
  }
`

export const ModalWrapper = styled.div`
  overflow: visible;
  & div {
    overflow: visible;
  }
  & > div > div {
    min-height: 305px;
    height: auto;
    max-height: 60%;
    width: 95%;
    max-width: 580px;
    h2 {
      padding-right: 0;
      font-size: ${(p) => p.theme.fontSizes.xl};
    }
  }
  @media ${mobileMediaQuery} {
    & > div > div {
      height: 100%;
      max-height: none;
      width: 100%;
      max-width: none;
    }
    input {
      width: 100%;
    }
  }
`

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
  @media ${mobileMediaQuery} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid ${(p) => p.theme.colors.background_dark};
    padding: 10px;
  }
`

export const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 10px;
`

export const HeadCountInput = styled(Input)`
  & > span {
    position: absolute;
    right: 25px;
    max-width: 91%;
    @media ${mobileMediaQuery} {
      width: 91%;
    }
  }
`

export const StyledText = styled(Text)`
  width: 100%;
  text-align: center;
  font-weight: ${(p) => p.theme.fontWeights.medium};
`

export const Box = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 0;
  gap: ${(p) => p.theme.spacings[4]}px;
  @media ${mobileMediaQuery} {
    flex-direction: column;
  }
`

export const DatepickerInput = styled(Datepicker)<DatepickerProps>`
  & div > input[type='text'] {
    background-color: ${(p) =>
      p.disabled ? p.theme.colors.background_dark : p.theme.colors.canvas};
  }
  & > div > span {
    height: 24px;
    @media ${mobileMediaQuery} {
      height: auto;
    }
  }
`

export const NewAddress = styled.div`
  color: ${(p) => p.theme.colors.primary};
  text-decoration: underline;
`

export const InputWrapper = styled.div<{ width?: string }>`
  position: relative;
  width: ${(p) => (p.width ? p.width : '100%')};
  & > div > span {
    height: ${(p) => p.theme.spacings[4]}px;
  }
`

export const SelectWrapper = styled.div`
  position: relative;
  z-index: 10;
  div > div[class$='-control'] {
    min-height: 44px;
  }
`
