import React from 'react'

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path d="M10 4c-3.875 0-8 6.757-8 12.832C2 22.907 5.582 26 10 26s8-3.093 8-9.168C18 10.757 13.875 4 10 4zm8.771 6.45C19.551 12.525 20 14.75 20 16.831c0 3.992-1.445 7.193-3.877 9.129C21.929 25.564 28 21.672 28 18c0-3.113-4.364-6.384-9.229-7.55zM11 14a1 1 0 110 2 1 1 0 010-2zm1.5 5a1.5 1.5 0 11-.001 3.001A1.5 1.5 0 0112.5 19zM23 19a1 1 0 110 2 1 1 0 010-2z" />
    </svg>
  )
}

export default Icon
