import React from 'react'

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75 60">
      <path
        fillRule="evenodd"
        d="M.809.606L4.566-3l16.828 16.15a5.108 5.108 0 013.389-4.138l13.284-4.637a5.576 5.576 0 013.632-.004l13.285 4.641c2.09.73 3.497 2.654 3.497 4.79v14.652c0 .559-.113 1.096-.29 1.609l12.734 4.447c2.09.73 3.497 2.654 3.497 4.79v14.652c0 1.997-1.224 3.818-3.119 4.642l-1.759.767 9.413 9.033L75.201 72 .809.606zM51.704 13.29l-11.82-4.129-11.822 4.129 11.821 4.123 11.821-4.123zm-25.007 4.95l10.53 3.67v6.434L26.696 18.24zm-5.412 10.212v-1.115l10.477 10.05-7.82-2.73-11.821 4.13 11.82 4.123 9.954-3.471 8.645 8.296v6.215l10.627 4.637v-.653l6.144 5.892-1.287.558a5.467 5.467 0 01-2.195.458c-.752 0-1.504-.15-2.205-.458l-13.28-5.792c-.099-.044-.186-.105-.274-.165a2.395 2.395 0 00-.182-.119 2.72 2.72 0 00-.178.115c-.09.062-.18.124-.284.169l-13.284 5.792a5.467 5.467 0 01-2.195.458c-.752 0-1.504-.15-2.206-.458L8.462 58.593c-1.894-.821-3.118-2.645-3.118-4.642V39.3c0-2.137 1.406-4.062 3.497-4.79l12.74-4.448a4.895 4.895 0 01-.296-1.609zm34.54 6.207l11.82 4.129-11.82 4.123-5.37-1.872-3.441-3.302 8.81-3.078zm-34.54 12.748l-10.627-3.705v10.25l10.628 4.636v-11.18z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default Icon
