import { sortBy } from 'lodash'
import {
  MenuAvailabilityFragment,
  MenuAvailabilityWithItemsPagedFragment,
} from '@/generated/graphql'
import { isNotNil, Replace } from '../utils/typing'
import { createMenuCategories, MenuCategory } from './menu-category'
import {
  createMenuFilterCategories,
  MenuFilterCategory,
  SelectedMenuFilterCategory,
} from './menu-category-filter'
import { createMenuItems, MenuItem } from './menu-item'
import { createMenuItemFilters, MenuItemFilter, MenuItemFilterSections } from './menu-item-filter'
import { MenuVendorCategory } from './menu-vendor'

export type MenuAvailability = Replace<
  MenuAvailabilityFragment,
  {
    menuCategories: MenuCategory[]
    filterCategories: MenuFilterCategory[]
    filterSections: MenuItemFilterSections
    items: MenuItem[]
  }
>

const itemNameFilter = (item: MenuItem, itemName?: string) => {
  if (!itemName) return true

  return item.name.toLowerCase().includes(itemName.toLowerCase())
}

const itemCategoryFilter = (item: MenuItem, selectedCategory?: SelectedMenuFilterCategory) => {
  if (!selectedCategory) return true

  if (selectedCategory.id === 'everything') return true
  if (selectedCategory.id === 'hot_dishes') return item.isHot
  if (selectedCategory.id === 'cold_dishes') return !item.isHot

  return item.categoryIdArray?.includes(Number(selectedCategory.id))
}

const menuItemFilters = (item: MenuItem, filters?: MenuItemFilter[]) => {
  if (!filters) return true

  return filters.every((filter) => filter.predicate(item))
}

export const createMenuAvailability = ({
  availability,
  vendorCategories,
  selectedCategory,
  useServerSideItems,
  filters,
}: {
  availability: MenuAvailabilityWithItemsPagedFragment
  vendorCategories: MenuVendorCategory[]
  selectedCategory?: SelectedMenuFilterCategory
  useServerSideItems?: boolean
  filters: {
    itemName?: string
    goals: MenuItemFilter[]
    dietaries: MenuItemFilter[]
    allergens: MenuItemFilter[]
  }
}): MenuAvailability => {
  const items = createMenuItems((availability.itemsPagedv2.items ?? []).filter(isNotNil))
    .filter((item) => (useServerSideItems ? itemCategoryFilter(item, selectedCategory) : true))
    .filter((item) => (useServerSideItems ? itemNameFilter(item, filters.itemName) : true))
    .filter((item) =>
      useServerSideItems
        ? menuItemFilters(item, [...filters.goals, ...filters.dietaries, ...filters.allergens])
        : true,
    )
    .map((item) => {
      if (!item.categoryIdArray?.length || !selectedCategory || !Number(selectedCategory.id))
        return item
      return {
        ...item,
        categoryIdArray: [Number(selectedCategory.id)],
      }
    })

  const stats = availability.itemsPagedv2.stats

  const categoriesWithCounts = stats.categories.reduce<Record<string, number>>(
    (categories, category) => {
      return { ...categories, [category.label]: category.value }
    },
    { everything: availability.itemsPagedv2.pageInfo.count },
  )

  const dietariesAndAllergensWithCounts = [...stats.allergens, ...stats.dietaries].reduce<
    Record<string, number>
  >((dietariesAndAllergens, dietaryOrAllergen) => {
    return { ...dietariesAndAllergens, [dietaryOrAllergen.name]: dietaryOrAllergen.value }
  }, {})

  const allMenuCategories = createMenuCategories(items, vendorCategories, categoriesWithCounts)

  const displayedMenuCategories = allMenuCategories
    .filter(isNotNil)
    .map((c) => ({
      ...c,
      items: sortBy(c.items, (item) => item.position),
    }))
    .filter((c) => c.items.length > 0)

  return {
    ...availability,
    menuCategories: displayedMenuCategories,
    filterCategories: createMenuFilterCategories(allMenuCategories, categoriesWithCounts),
    filterSections: createMenuItemFilters(dietariesAndAllergensWithCounts),
    items,
  }
}
