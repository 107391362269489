import React from 'react'
import { PopoverWrapper, PopoverGroup, PopoverItem, PopoverDivider } from './styles'

export type PopoverItemType = {
  value: string
  children: React.ReactNode
}

export type PopoverGroupType = {
  title?: string
  items: PopoverItemType[]
}

type Props = {
  useNewSavedAddresses?: boolean
  data: PopoverGroupType[]
  onSelect: (value: PopoverItemType['value']) => Promise<void> | void
  show?: boolean
}

const Popover: React.FC<Props> = ({
  data,
  onSelect,
  show = false,
  useNewSavedAddresses = false,
}) => {
  if (data.length < 1 || !show) return null
  return (
    <PopoverWrapper data-testid="address-search-results">
      {data.map((group, index) => {
        let title: React.ReactNode
        if (group.title) {
          title = (
            <PopoverGroup useNewSavedAddresses={useNewSavedAddresses} key={index}>
              {group.title}
            </PopoverGroup>
          )
        }
        return (
          <div key={index}>
            {title}
            {group.items.map((item, index) => (
              <PopoverItem key={index} onClick={() => onSelect(item.value)}>
                {item.children}
              </PopoverItem>
            ))}
            {index < data.length - 1 && (
              <PopoverDivider>
                <hr />
              </PopoverDivider>
            )}
          </div>
        )
      })}
    </PopoverWrapper>
  )
}

export default Popover
