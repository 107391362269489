import React from 'react'
import { Chip, chipClasses, ChipProps } from '@mui/material'

const StyledChip: React.FC<{ label: string; icon: ChipProps['icon'] }> = ({ label, icon }) => {
  return (
    <Chip
      icon={icon}
      label={label}
      size="small"
      sx={{
        fontWeight: 600,
        backgroundColor: 'background.default',
        color: 'text.secondary',
        [`.${chipClasses.icon}`]: { color: 'text.disabled' },
      }}
    />
  )
}

export default StyledChip
