import React from 'react'

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="39" height="36" viewBox="0 0 39 36">
      <path d="M10.635 0C4.761 0 0 4.794 0 10.71c0 10.297 11.86 20.135 17.402 24.517l.016.012.127.103.003-.004c.562.428 1.247.66 1.952.662.706 0 1.392-.233 1.955-.662v.004c.011-.009.027-.02.038-.03l.029-.022.031-.028C27.078 30.896 39 21.032 39 10.71 39 4.794 34.239 0 28.365 0 22.75 0 19.5 4.91 19.5 4.91S16.25 0 10.635 0z" />
    </svg>
  )
}

export default Icon
