import React from 'react'

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path d="M13.984-.016C13.434-.004 12.992.45 13 1v3c-.004.36.184.695.496.879.313.18.695.18 1.008 0 .312-.184.5-.52.496-.879V1a1.003 1.003 0 00-1.016-1.016zM20.176.992a1.03 1.03 0 00-.543.145s-2.281 1.293-3.04 3.89c-.23.79-.554 1.551-.948 2.078C14.915 7.012 14.285 7 14 7c-1 0-6 0-6 4 0 .816.094 1.852.262 3H9a1 1 0 110 2h-.395a59.05 59.05 0 001.207 5H11a1 1 0 110 2h-.563c1.126 3.36 2.454 6 3.563 6 1.441 0 3.254-4.453 4.504-9.152A.988.988 0 0118 19c0-.54.43-.973.965-.992.328-1.403.59-2.774.77-4.008H17a1 1 0 110-2h2.96c.024-.36.04-.695.04-1 0-1.848-1.066-2.836-2.316-3.371.375-.66.632-1.367.828-2.043.5-1.707 2.136-2.723 2.136-2.723.391-.222.586-.68.477-1.117a1.002 1.002 0 00-.95-.754zM7.965.996a1 1 0 00-.414 1.898s1.586.876 1.84 2.286c.05.36.296.668.64.797a.99.99 0 001.004-.184 1 1 0 00.324-.969c-.468-2.578-2.91-3.719-2.91-3.719a.958.958 0 00-.484-.109zm0 0" />
    </svg>
  )
}

export default Icon
