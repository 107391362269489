import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

type Props = {
  message: string
}

const ToastWrapper = styled.div<{ show: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  color: ${(p) => p.theme.colors.text_contrast};
  background-color: ${(p) => p.theme.colors.error_main};
  height: 68px;
  transform: ${(p) => (p.show ? 'translateY(0)' : 'translateY(-68px)')};
  transition: transform 0.2s;
`

const Toast: React.FC<Props> = ({ message }) => {
  const [show, setShow] = useState(true)
  useEffect(() => {
    setTimeout(() => setShow(false), 5000)
  })
  return <ToastWrapper show={show}>{message}</ToastWrapper>
}

export default Toast
