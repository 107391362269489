import React from 'react'

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="36" viewBox="0 0 48 36">
      <path d="M47.74 16.234C47.433 15.744 36.353 0 18.857 0c-.948 0-1.714.768-1.714 1.714v3.66c-4.241 2.546-7.89 6.041-10.696 9.273 0 0-3.168-5.064-3.198-5.13a1.719 1.719 0 00-2.301-.764A1.695 1.695 0 000 10.29c0 1.69 1.714 6.854 1.714 6.854S0 22.265 0 23.998a1.713 1.713 0 003.195.866c.036-.062 3.179-4.642 3.179-4.642 3.747 4.448 9.125 9.241 15.912 11.297v2.767c0 .946.766 1.714 1.714 1.714 4.912 0 8.709-3.564 10.324-5.18 10.104-5.428 13.294-12.51 13.496-12.91a1.72 1.72 0 00-.08-1.676zm-10.883 2.623a2.572 2.572 0 010-5.143 2.572 2.572 0 010 5.143z" />
    </svg>
  )
}

export default Icon
