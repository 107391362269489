import React from 'react'

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path d="M14.984.988c-.304.004-.59.149-.777.387 0 .004-.004.004-.004.008l-4.91 4.91a.992.992 0 00-.273.973.99.99 0 00.714.714.992.992 0 00.973-.273L14 4.414V28c-.004.36.184.695.496.879.313.18.695.18 1.008 0 .312-.184.5-.52.496-.879V4.414l3.293 3.293c.25.262.625.367.973.273a.99.99 0 00.714-.714.992.992 0 00-.273-.973l-4.914-4.918a1.008 1.008 0 00-.809-.39zm0 0" />
    </svg>
  )
}

export default Icon
