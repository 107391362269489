import React from 'react'

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path d="M14.5 3c-1.801 0-3.4.866-4.402 2.203C9.408 6.123 9.057 7.943 8 9s-2.53 1.424-3.52 2.14a5.992 5.992 0 00-2.285 6.374c.172.65.843 1.017 1.485.816 8.857-2.766 11.169-9.252 11.28-9.6.253-.789.72-.758.99-.644.27.114.41.529.251 1.057-.115.384-1.888 7.58-11.158 10.847-.513.181-.57.887-.104 1.166A5.94 5.94 0 008 22c7 0 12-8 12-13.5A5.5 5.5 0 0014.5 3zm7.28 12.156c-.47.001-1.007.072-1.565.13-.965 1.938-2.294 3.792-3.938 5.29 3.41.711 5.974.19 6.176.147a.998.998 0 01.432 1.95c-.26.059-3.998.828-8.639-.556-1.694 1.07-3.617 1.75-5.732 1.848 4.243 3.754 11.61 3.744 15.674 1.467a5.5 5.5 0 00-1.965-10.25 3.34 3.34 0 00-.444-.026z" />
    </svg>
  )
}

export default Icon
