import { FragmentType, graphql, useFragment } from '@/generated/gql'
import { CustomerFacingExistingGmOrderItemInternal } from './useExistingOrderItems'
import { useMutation } from '@apollo/client'
import { useState } from 'react'
import { UpdateGmOrderUpdateItemInput } from '@/generated/gql/graphql'
import { UpdateGmOrderAddItemInput } from '@/generated/graphql'

const useExistingOrderMutationsOrderDataFragment = graphql(`
  fragment UseExistingOrderMutations_OrderDataFragment on GmOrder {
    id
  }
`)

const useExistingOrderMutationsUpdateOrderMutation = graphql(`
  mutation UseExistingOrderMutations_UpdateOrderMutation($input: UpdateGmOrderInput!) {
    updateGmOrder(input: $input) {
      id
    }
  }
`)

type Props = {
  orderData: FragmentType<typeof useExistingOrderMutationsOrderDataFragment> | null
  updatedOrderItems: CustomerFacingExistingGmOrderItemInternal[]
  removedOrderItems: CustomerFacingExistingGmOrderItemInternal[]
  updatedOrderTotalIncTax: number
  onSuccessfulSave?: (orderData: { orderId: string }) => void
}

export const useExistingOrderMutations = ({
  orderData: orderDataProp,
  updatedOrderItems,
  removedOrderItems,
  updatedOrderTotalIncTax,
  onSuccessfulSave = () => {},
}: Props) => {
  const orderData = useFragment(useExistingOrderMutationsOrderDataFragment, orderDataProp)
  const [runMutation, { error }] = useMutation(useExistingOrderMutationsUpdateOrderMutation)
  const [saving, setSaving] = useState(false)

  const saveOrderChanges = async () => {
    if (!orderData) return
    setSaving(true)
    const { updateItems, addItems } = updatedOrderItems.reduce<{
      updateItems: UpdateGmOrderUpdateItemInput[]
      addItems: UpdateGmOrderAddItemInput[]
    }>(
      (sortedItems, item) => {
        const updateItems = [...sortedItems.updateItems]
        const addItems = [...sortedItems.addItems]
        if (item.isExistingOrderItem && item.hasChanges)
          updateItems.push({
            orderItemId: item.id,
            quantity: item.qty,
            ...(item.options.length && {
              options: item.options.map((option) => ({
                optionItemId: option.id,
                quantity: option.qty,
              })),
            }),
          })
        else if (!item.isExistingOrderItem)
          addItems.push({
            liveItemId: item.liveItemId,
            variationId: item.variationId ?? '',
            note: item.name,
            quantity: item.qty,
            options: item.options.map((option) => ({
              optionItemId: option.id,
              optionCategoryId: option.optionCategoryId ?? '',
              quantity: option.qty,
            })),
          })
        return { updateItems, addItems }
      },
      { updateItems: [], addItems: [] },
    )
    try {
      await runMutation({
        variables: {
          input: {
            id: orderData.id,
            proposedTotalIncTax: updatedOrderTotalIncTax,
            removeItems: removedOrderItems.map((item) => ({ orderItemId: item.id })),
            updateItems,
            addItems,
          },
        },
      })
      onSuccessfulSave({ orderId: orderData.id })
    } catch (err) {
      //
    }
    setSaving(false)
  }

  return { saveOrderChanges, saving, saveError: error }
}
