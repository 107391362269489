import React from 'react'

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8">
      <mask
        id="mask0_5732_5560"
        style={{ maskType: 'alpha' }}
        width="8"
        height="8"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M.294.293h7.412v7.412H.294V.293z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#mask0_5732_5560)">
        <path
          fillRule="evenodd"
          d="M2.356.46a.34.34 0 01.296-.167h2.695a.34.34 0 01.297.167.34.34 0 010 .34.34.34 0 01-.297.167v2.695l2.206 2.942a.675.675 0 01-.521 1.1H.968a.674.674 0 01-.521-1.1l2.205-2.942V.967A.34.34 0 012.356.8a.34.34 0 010-.34zm2.317 2.528H3.326V.966h1.347v2.022zm-1.01.674a.337.337 0 11-.001.675.337.337 0 010-.675zM5.347 6.19a.506.506 0 10-1.01 0 .506.506 0 001.01 0z"
          clipRule="evenodd"
        />
      </g>
    </svg>
  )
}

export default Icon
