import React from 'react'

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path d="M26 14.811C25.178 12.242 23.303 4 8 4a1 1 0 00-1 1c0 .976 1.936 2.276 1.936 4.962C7.518 11.289 7.953 13 6.503 13 5.221 13 4.946 10 4 10a.999.999 0 00-.86.49C3.093 10.569 2 12.444 2 15s1.093 4.431 1.14 4.51c.179.303.506.49.86.49 1.003 0 1.505-3 2.503-3S7.546 18.607 9 20c0 1.113-1 3.892-1 5a1 1 0 001 1c12.389 0 19-7.351 19-9.298 0-.697-1.577-1.306-2-1.891zM21 16a1 1 0 110-2 1 1 0 010 2z" />
    </svg>
  )
}

export default Icon
