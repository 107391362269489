import React, { useEffect, useMemo, useState } from 'react'
import { useToggle } from 'react-use'
import { useTheme } from 'styled-components'
import { debounce } from 'lodash'
import { match } from 'ts-pattern'
import { amountToPriceString, Currency } from '@teamfeedr/utils--money'
import { validateBasketItemUpdate } from '@teamfeedr/utils--gm-validation'
import { calculateItemSubtotal } from '@teamfeedr/utils--basket'
import { BasketItemFragment, MenuItemOptionType } from '@/generated/graphql'
import { areFieldsNotNil } from '../../utils/typing'
import { Box, Button, Card, Divider, IconButton, Stack, Typography } from '@mui/material'
import {
  CloseRounded,
  ExpandLessRounded,
  ExpandMoreRounded,
  People,
  StickyNote2Outlined,
  Star,
} from '@mui/icons-material'
import QuantitySelect from '@/components/QuantitySelect'

export type Props = {
  item: BasketItemFragment
  currency: Currency
  debounceQuantityChange?: boolean
  onQuantityChange?: (qty: number) => void
  onDeleteClick?: () => void
  showPricesExTax: boolean
  taxAcronym: string
}

const BasketItemCard: React.FC<Props> = ({
  item,
  currency,
  debounceQuantityChange = true,
  onQuantityChange,
  onDeleteClick,
  showPricesExTax,
  taxAcronym,
}) => {
  const theme = useTheme()
  const [quantity, setQuantity] = useState(item.qty)
  const [showOptions, toggleShowOptions] = useToggle(false)

  const triggerQuantityChangeWithDebounce = useMemo(
    () => debounce((value: number) => onQuantityChange && onQuantityChange(value), 500),
    [onQuantityChange],
  )

  const onChange = (value: number) => {
    if (!onQuantityChange) return

    if (debounceQuantityChange) {
      setQuantity(value)
      triggerQuantityChangeWithDebounce(value)
    } else {
      onQuantityChange(value)
    }
  }
  const options = item.options
    .filter(areFieldsNotNil(['optionItem', 'category']))
    .map(({ optionItem, qty, category, item }) => ({
      price: (showPricesExTax ? optionItem.priceExTax : optionItem.price) || 0,
      qty,
      type: category.optionType || undefined,
      item,
    }))
  const itemPrice = item.item?.variations
    ? item.item.variations[0].priceCustomerFacing
    : item.item?.price || 0
  const itemPriceExTax = item.item?.variations
    ? item.item.variations[0].priceCustomerFacingExTax
    : item.item?.priceExTax || 0
  const itemTaxRate = item.item?.variations
    ? item.item.variations[0].taxRate
    : item.item?.taxRate || 0
  const itemTaxValue =
    item.item?.variations && item.item.variations[0].taxValue
      ? item.item.variations[0].taxValue
      : item.item?.taxValue || 0

  const itemSubtotals = calculateItemSubtotal(
    {
      itemPrice,
      itemPriceExTax,
      itemTaxRate,
      itemTaxValue,
    },
    item.qty,
    options,
  )
  const total = showPricesExTax ? itemSubtotals.subtotalExTax : itemSubtotals.subtotal
  const itemHasBeenEdited = validateBasketItemUpdate(item) || undefined

  useEffect(() => {
    setQuantity(item.qty)
  }, [item.qty])

  if (item.item) {
    const isGmActive = item.item.variations && item.item.variations[0].isActive
    const servings = (item.item.variations ? item.item.variations[0].servings : 1) || 1
    const isCheckoutBasket = !onQuantityChange || !isGmActive

    return (
      <Card
        sx={{
          borderWidth: '1px',
          borderStyle: itemHasBeenEdited ? 'solid' : 'none',
          borderColor: 'error.main',
        }}
        variant="elevation"
      >
        <Box sx={{ px: 2, py: 1 }}>
          <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography gutterBottom variant="body2" component="div" color="text.primary">
              {isCheckoutBasket ? `${item.qty}x ` : null}
              {item.item.name}
            </Typography>
            {onDeleteClick && isGmActive && (
              <IconButton style={{ margin: 0, padding: 4 }} onClick={onDeleteClick}>
                <CloseRounded />
              </IconButton>
            )}
          </Stack>
          <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Stack direction="row" alignItems="center">
              {!isCheckoutBasket && (
                <QuantitySelect
                  min={
                    item.item.variations ? item.item.variations[0].minQty || undefined : undefined
                  }
                  onChange={onChange}
                  quantity={quantity}
                  disabled={isCheckoutBasket}
                  max={2000}
                />
              )}
              <People color="action" fontSize="small" />
              &nbsp;
              <Typography color="action" variant="caption">
                Serves {servings * item.qty}
              </Typography>
            </Stack>
            {itemPrice && (
              <Typography variant="body2" component="div">
                {amountToPriceString(currency, total, true)} (ex {taxAcronym})
              </Typography>
            )}
          </Stack>
        </Box>
        {item.options?.length ? (
          <Box>
            <Divider variant="middle" />
            <Box sx={{ px: 2, py: 1 }}>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Star color="action" fontSize="small" />
                <Typography variant="caption">Options</Typography>
                <Button
                  sx={{ marginLeft: 'auto' }}
                  size="small"
                  endIcon={showOptions ? <ExpandLessRounded /> : <ExpandMoreRounded />}
                  onClick={toggleShowOptions}
                >
                  {showOptions ? 'Hide' : 'Show'}
                </Button>
              </Stack>
              {showOptions && (
                <Stack direction="row" justifyContent={isCheckoutBasket ? 'center' : 'flex-start'}>
                  {item.options
                    .filter(areFieldsNotNil(['category', 'optionItem']))
                    .map((option) => {
                      const optionItemPrice =
                        option.optionItem.priceCustomerFacing || option.optionItem.price
                      const optionItemPriceExTax =
                        option.optionItem.priceCustomerFacingExTax || option.optionItem.priceExTax
                      const optionPriceToShow = showPricesExTax
                        ? optionItemPriceExTax
                        : optionItemPrice
                      const taxCopy = showPricesExTax ? ` ex ${taxAcronym}` : ''
                      return option.item ? (
                        <Typography
                          key={option.item._id}
                          variant="caption"
                          color={theme.colors.text_semi_muted}
                        >
                          {match(option.category.optionType)
                            .with(MenuItemOptionType.Ticks, null, () => item.qty)
                            .with(MenuItemOptionType.Numbers, () => option.qty)
                            .exhaustive()}{' '}
                          x {option.item.name}{' '}
                          {optionPriceToShow
                            ? `(${amountToPriceString(
                                currency,
                                optionPriceToShow,
                                true,
                              )}${taxCopy})`
                            : ''}
                        </Typography>
                      ) : null
                    })}
                </Stack>
              )}
            </Box>
          </Box>
        ) : null}
        {item.note && (
          <div>
            <Divider variant="middle" />
            <Box sx={{ px: 2, py: 1 }}>
              <Stack direction="column">
                <Stack direction="row" alignItems="center">
                  <StickyNote2Outlined fontSize="small" />
                  &nbsp;
                  <Typography color={theme.colors.text_semi_muted} variant="caption">
                    {item.note}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </div>
        )}
      </Card>
    )
  }
  return null
}

export default BasketItemCard
