import React from 'react'

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        style={{
          lineHeight: 'normal',
          textIndent: '0',
          textAlign: 'start',
          WebkitTextDecorationLine: 'none',
          textDecorationLine: 'none',
          WebkitTextDecorationStyle: 'solid',
          textDecorationStyle: 'solid',
          WebkitTextDecorationColor: '#000',
          textDecorationColor: '#000',
          textTransform: 'none',
          isolation: 'auto',
          mixBlendMode: 'normal',
        }}
        d="M11 3a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1zm4 0a1 1 0 100 2h9c1.117 0 2 .883 2 2s-.883 2-2 2H12.307a9.51 9.51 0 00-5.78 1.963.713.713 0 00-.078 1.072l.22.22c.409.409.43 1.1.003 1.487a1 1 0 01-1.379-.035l-.078-.078a.71.71 0 00-1.123.154 8.65 8.65 0 00-.873 2.299.756.756 0 00.738.918c.484 0 .93.324 1.023.799A1 1 0 014 19h-.063a.756.756 0 00-.74.908 8.196 8.196 0 001.405 3.197.56.56 0 00.775.12c.262-.187.633-.337 1.033-.14.548.272.738.927.356 1.519-.145.225-.06.527.166.671.739.473 1.7.898 2.685 1.184a.683.683 0 00.678-.164A.99.99 0 0111 26c.384 0 .708.22.871.54a.745.745 0 00.633.384c.19.009.381.014.576.014.241 0 1.136.006 2.293.013a.844.844 0 00.754-.426.988.988 0 011.756.016c.14.273.439.43.746.432L23 27a3 3 0 003-3v-1a1 1 0 00-1-1h-5a2 2 0 00-2 2h-4.873c-1.626 0-3.081-1.325-3.107-2.951a3.001 3.001 0 013-3.049H17a7 7 0 007-7c2.062 0 3.718-1.606 3.922-3.617A1 1 0 0028 7c0-2.197-1.803-4-4-4h-9zm4.13 8a1.5 1.5 0 110 3.001 1.5 1.5 0 010-3.001z"
        fontFamily="sans-serif"
        fontWeight="400"
        overflow="visible"
      />
    </svg>
  )
}

export default Icon
