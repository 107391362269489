import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      fillRule="evenodd"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      version="1"
      viewBox="0 0 10000 12500"
    >
      <path
        d="M3014 6866c319 264 762 427 1254 427 855 0 1373-357 1593-818 104-219 143-462 119-701-24-240-111-477-258-683-57-80-123-155-199-223-39 43-80 84-124 124-311 282-707 432-1106 444-400 13-805-111-1136-377-56-45-111-96-164-151-36 31-71 63-103 97-235 240-375 544-375 873 0 384 191 733 499 988zm3105-4086c-148-40-295-63-439-71 10 33 20 64 32 95 119 305 344 546 619 693 276 148 603 201 925 132 10-2 21-5 32-7-21-36-44-71-69-105-239-338-625-610-1100-737zM3628 4145c-6-60-8-120-7-181 2-126 19-252 52-375 131-490 488-869 958-1084 458-209 1024-263 1591-111s1031 482 1322 892c300 421 419 927 288 1418-92 344-296 634-571 850-244 191-545 325-876 389-4 242-58 483-163 704-281 590-920 1046-1954 1046-587 0-1120-198-1508-519-399-329-645-787-645-1296 0-437 183-838 489-1151 263-270 618-475 1024-582z"
        className="fil0"
      />
    </svg>
  )
}

export default Icon
