import React from 'react'

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75 60">
      <path
        fillRule="evenodd"
        d="M4.398-2L.64 1.606 75.032 73l3.758-3.606-17.82-17.101V22.75c0-.49-.147-.974-.42-1.385L53 10.136V7.452c0-1.41-1.188-2.55-2.657-2.55h-26.57c-1.468 0-2.656 1.14-2.656 2.55v2.684l-1.598 2.376L4.398-2zm21.42 14.553H47.69l-5.319 7.65h-14.84l-4.168-4 2.455-3.65zm-12.137 8.65l-.114.165c-.053.081-.077.177-.12.263-.042.08-.082.155-.114.24a2.452 2.452 0 00-.187.88V63.55c0 1.41 1.188 2.55 2.657 2.55h42.51c.603 0 1.134-.23 1.578-.553L42.37 48.738v12.26h-23.91V25.79l-4.78-4.587zm19.163 4.098h9.527v9.143l-9.527-9.143z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default Icon
