import React from 'react'
import Modal from '@teamfeedr/ui--modal'
import { ModalWrapper, ModalHeader, ButtonWrapper, StyledText } from './index.styles'
import Icon from '@teamfeedr/ui--icon'
import { useTheme } from 'styled-components'
import { Button } from '@mui/material'
import { useRouter } from '@/components/Link'

type Props = {
  closeModal: () => void
  basketId: string
}

const UnauthenticatedModal: React.FC<Props> = ({ closeModal, basketId }) => {
  const theme = useTheme()
  const router = useRouter()
  const continueAndCloseModal = (url: string) => {
    const redirectAfterAuth = `/office-catering/checkout/?id=${basketId}`
    localStorage.setItem('redirectAfterAuth', JSON.stringify({ value: redirectAfterAuth }))
    closeModal()
    const href = `${url}?destination=${encodeURIComponent(redirectAfterAuth)}&intent=gm`
    router.push(href)
  }
  return (
    <ModalWrapper>
      <Modal.Wrapper
        centerHeader
        header={
          <ModalHeader>
            <Icon icon="person" size={28} fill={theme.colors.primary_lightest} variation="circle" />
            Sign up to continue
          </ModalHeader>
        }
        body={
          <>
            <Modal.Section centerContent padding={{ x: 24 }}>
              <StyledText>
                Signing up takes a few minutes and we’ll keep your basket safe while you do it.
              </StyledText>
              <ButtonWrapper>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => continueAndCloseModal('/account/signup')}
                >
                  Sign up
                </Button>
                <Button fullWidth onClick={() => continueAndCloseModal('/authentication/login')}>
                  Sign in
                </Button>
              </ButtonWrapper>
            </Modal.Section>
          </>
        }
        onClose={() => closeModal()}
      />
    </ModalWrapper>
  )
}

export default UnauthenticatedModal
