import React from 'react'

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path d="M14.984.988A.999.999 0 0014 2v23.586l-3.293-3.293a1.006 1.006 0 00-.715-.305c-.41.004-.773.25-.93.625a1 1 0 00.231 1.094l4.914 4.91c.188.25.48.395.793.395a.992.992 0 00.793-.387c0-.004.004-.004.004-.008l4.91-4.91a.992.992 0 00.273-.973.99.99 0 00-.714-.714.992.992 0 00-.973.273L16 25.586V2a1.003 1.003 0 00-.293-.723.984.984 0 00-.723-.289zm0 0" />
    </svg>
  )
}

export default Icon
