/* eslint-disable camelcase */
import { FragmentType, graphql, useFragment } from '@/generated/gql'
import {
  ProposedGmOrderTotalsInput,
  UseInputRelatedOrderData_OrderDataFragmentFragment,
} from '@/generated/gql/graphql'
import { numberFormat } from '@/helpers/numbers'
import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { CustomerFacingExistingGmOrderItemInternal } from './useExistingOrderItems'
import {
  ProposedGmOrderTotalsInputItem,
  ProposedGmOrderTotalsOptionItem,
} from '@/generated/graphql'

const useInputRelatedVariableOrderDataFragment = graphql(`
  fragment UseInputRelatedOrderData_OrderDataFragment on GmOrder {
    id
    subtotalExTax
    headCount
    deliveryStart
    userEditCutOffTime
    fees {
      description
      totalExTax
    }
    discountBreakdown {
      totalIncTax
      totalExTax
    }
    orderTotalBreakdown {
      taxRateTotals {
        rate
        totalExTax
      }
      totalIncTax
      taxValue
    }
    vendorLocation {
      id
    }
    account {
      id
      tenantId
    }
    accountLocation {
      id
      lat
      lng
    }
  }
`)

const useInputRelatedVariableOrderDataProposedTotalsQuery = graphql(`
  query UseInputRelatedOrderData_ProposedTotalsQuery($input: ProposedGmOrderTotalsInput!) {
    proposedGmOrderTotals(input: $input) {
      id
      fees {
        description
        totalExTax
      }
      discountBreakdown {
        totalIncTax
        totalExTax
      }
      orderTotalBreakdown {
        taxRateTotals {
          rate
          totalExTax
        }
        totalIncTax
        taxValue
      }
      subtotalExTax
      orderPlacementCutOffTime
      minimumOrderValue
    }
  }
`)

type Fees = Array<{ description: string; totalExTax: string }>
type TaxRateTotals = Array<{ rate: string; value: string }>
type GrandTotals = {
  totalIncTax: string
  totalTax: string
  subtotalExTax: string
  discountIncTax?: string
}

type Props = {
  orderData: FragmentType<typeof useInputRelatedVariableOrderDataFragment> | null
  orderItems: Array<CustomerFacingExistingGmOrderItemInternal> | null
}

export const useInputRelatedVariableOrderData = ({
  orderData: orderDataProp,
  orderItems,
}: Props) => {
  const [runProposedTotalsQuery] = useLazyQuery(useInputRelatedVariableOrderDataProposedTotalsQuery)
  const orderData = useFragment(useInputRelatedVariableOrderDataFragment, orderDataProp)

  const [fees, setFees] = useState<Fees>([])
  const [taxRateTotals, setTaxRateTotals] = useState<TaxRateTotals>([])
  const [subtotalExTaxAsNumber, setSubtotalExTaxAsNumber] = useState<number>(0)
  const [totalIncTaxAsNumber, setTotalIncTaxAsNumber] = useState<number>(0)
  const [grandTotals, setGrandTotals] = useState<GrandTotals>({
    subtotalExTax: '',
    totalIncTax: '',
    totalTax: '',
  })
  const [userEditCutOffTime, setUserEditCutOffTime] = useState<Date | null>(null)
  const [minimumOrderValue, setMinimumOrderValue] = useState(0)
  const [loading, setLoading] = useState(false)

  const mapGraphqlFeesToRequiredState = (
    input: UseInputRelatedOrderData_OrderDataFragmentFragment['fees'],
  ): Fees => {
    return input.map((fee) => ({
      description: fee.description,
      totalExTax: numberFormat(fee.totalExTax / 100),
    }))
  }

  const mapGraphqlTaxRateTotalsToRequiredState = (
    input: UseInputRelatedOrderData_OrderDataFragmentFragment['orderTotalBreakdown']['taxRateTotals'],
  ): TaxRateTotals => {
    return input.map((taxRateTotal) => ({
      rate: (taxRateTotal.rate * 100).toFixed(2),
      value: numberFormat(taxRateTotal.totalExTax / 100),
    }))
  }

  const mapGraphqlTotalsToRequiredState = ({
    totalIncTax,
    totalTax,
    subtotalExTax,
    discountIncTax,
    discountExTax,
  }: {
    totalIncTax: number
    totalTax: number
    subtotalExTax: number
    discountIncTax: number
    discountExTax: number
  }): GrandTotals => {
    return {
      subtotalExTax: numberFormat(subtotalExTax / 100),
      totalIncTax: numberFormat(totalIncTax / 100),
      totalTax: numberFormat(totalTax / 100),
      ...(discountIncTax < 0 && { discountIncTax: numberFormat((-1 * discountIncTax) / 100) }),
      ...(discountExTax < 0 && { discountExTax: numberFormat((-1 * discountExTax) / 100) }),
    }
  }

  useEffect(() => {
    if (!orderData) return
    const mappedFees = mapGraphqlFeesToRequiredState(orderData.fees)
    setFees(mappedFees)
    const mappedTaxRateTotals = mapGraphqlTaxRateTotalsToRequiredState(
      orderData.orderTotalBreakdown.taxRateTotals,
    )
    setTaxRateTotals(mappedTaxRateTotals)
    const mappedTotals = mapGraphqlTotalsToRequiredState({
      totalIncTax: orderData.orderTotalBreakdown.totalIncTax,
      totalTax: orderData.orderTotalBreakdown.taxValue,
      subtotalExTax: orderData.subtotalExTax,
      discountIncTax: orderData.discountBreakdown.totalIncTax,
      discountExTax: orderData.discountBreakdown.totalExTax,
    })
    setGrandTotals(mappedTotals)
    setSubtotalExTaxAsNumber(orderData.subtotalExTax)
    setTotalIncTaxAsNumber(orderData.orderTotalBreakdown.totalIncTax)
    setUserEditCutOffTime(new Date(orderData.userEditCutOffTime))
    setMinimumOrderValue(0)
  }, [orderData])

  useEffect(() => {
    if (!orderItems || !orderData) return
    const update = async () => {
      setLoading(true)
      const input: ProposedGmOrderTotalsInput = {
        accountId: orderData.account.id,
        deliveryLat: orderData.accountLocation.lat ?? 0,
        deliveryLng: orderData.accountLocation.lng ?? 0,
        headCount: Number(orderData.headCount),
        deliveryStartTime: orderData.deliveryStart,
        proposedItems: orderItems.map<ProposedGmOrderTotalsInputItem>((item) => {
          const itemQuantity = item.qty ?? 1
          const itemOptions = item.options ?? []
          const options = itemOptions.map<ProposedGmOrderTotalsOptionItem>((option) => ({
            priceExTax: option.priceExTax,
            priceIncTax: option.priceIncTax,
            taxRate: option.taxRate,
            qty: option.qty ?? 1,
          }))
          const itemData = {
            priceExTax: item.priceExTax,
            priceIncTax: item.priceIncTax,
            taxRate: item.taxRate,
            qty: itemQuantity,
            options,
          }
          return itemData
        }, []),
        tenantId: orderData.account.tenantId,
        vendorLocationId: Number(orderData.vendorLocation.id),
        existingOrderId: orderData.id,
      }
      const { data: proposedTotalsDataResult } = await runProposedTotalsQuery({
        variables: { input },
      })
      if (!proposedTotalsDataResult) return
      const { proposedGmOrderTotals } = proposedTotalsDataResult
      const mappedFees = mapGraphqlFeesToRequiredState(proposedGmOrderTotals.fees)
      setFees(mappedFees)
      const mappedTaxRateTotals = mapGraphqlTaxRateTotalsToRequiredState(
        proposedGmOrderTotals.orderTotalBreakdown.taxRateTotals,
      )
      setTaxRateTotals(mappedTaxRateTotals)
      const mappedTotals = mapGraphqlTotalsToRequiredState({
        totalIncTax: proposedGmOrderTotals.orderTotalBreakdown.totalIncTax,
        totalTax: proposedGmOrderTotals.orderTotalBreakdown.taxValue,
        subtotalExTax: proposedGmOrderTotals.subtotalExTax,
        discountIncTax: proposedGmOrderTotals.discountBreakdown.totalIncTax,
        discountExTax: proposedGmOrderTotals.discountBreakdown.totalExTax,
      })
      setSubtotalExTaxAsNumber(proposedGmOrderTotals.subtotalExTax)
      setTotalIncTaxAsNumber(proposedGmOrderTotals.orderTotalBreakdown.totalIncTax)
      setGrandTotals(mappedTotals)
      setUserEditCutOffTime(new Date(proposedGmOrderTotals.orderPlacementCutOffTime))
      setMinimumOrderValue(proposedGmOrderTotals.minimumOrderValue)
      setLoading(false)
    }
    update()
  }, [orderItems, runProposedTotalsQuery, orderData])

  return {
    fees,
    taxRateTotals,
    grandTotals,
    subtotalExTaxAsNumber,
    totalIncTaxAsNumber,
    userEditCutOffTime,
    minimumOrderValue,
    recalculatingTotals: loading,
    recalculatingFees: loading,
  }
}
