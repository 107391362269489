import React, { useState } from 'react'
import { amountToPriceString } from '@teamfeedr/utils--money'
import { useToggle } from 'react-use'
import { MenuItemModal } from './menu-item-modal'
import DietaryListMui from '@/components/DietaryListMui'
import { MenuItem } from '../../domain/menu-item'
import { MenuVendor } from '../../domain/menu-vendor'
import { AddItemPayload } from '../states/menu-page/add-item-mutation'
import Image from 'next/image'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  Stack as MuiStack,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { AddCircle, AddShoppingCart, Person, RemoveCircle, Star } from '@mui/icons-material'
import {
  SelectedOptionItem as SelectedOptionItemValidation,
  reachedMinimumOptionRequirements,
} from '@teamfeedr/utils--gm-validation'
import { useAuthState } from '../states/auth'
import { match } from 'ts-pattern'

export type Props = {
  item: MenuItem
  vendor: MenuVendor
  onAddItemToBasketClick: (item: AddItemPayload) => void
  basketIsLoading: boolean
  closeModalWithoutLoading: boolean
  showPricesExTax: boolean
  taxAcronym: string
  numberInCol: number
}

const MenuItemCard: React.FC<Props> = ({
  item,
  vendor,
  onAddItemToBasketClick,
  basketIsLoading,
  closeModalWithoutLoading,
  showPricesExTax,
  taxAcronym,
  numberInCol,
}) => {
  const theme = useTheme()
  const authState = useAuthState()
  const account = match(authState)
    .with({ type: 'authenticated' }, ({ user }) => user.account)
    .with({ type: 'unauthenticated' }, () => null)
    .with({ type: 'loading' }, () => null)
    .exhaustive()

  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))
  const [modalIsOpen, toggleModalIsOpen] = useToggle(false)
  const [quantity, setQuantity] = useState(item.minQty || 1)
  let servesAmount = `${item.servings}`
  let price = showPricesExTax ? item.priceExTax : item.price
  let priceCaveat = ''
  if (item.isPricePerHead) {
    servesAmount = `${item.minQty}`
    if (item.minQty > 1) {
      servesAmount += '+'
      price = price * item.minQty
      priceCaveat = `(for ${item.minQty})`
    }
  }

  const handleAddItem = () => {
    if (quantity <= 0) return
    onAddItemToBasketClick({ item, qty: quantity })
  }

  const handleModalOpen = (e: any) => {
    e.stopPropagation()
    toggleModalIsOpen()
  }

  const quickAddDisabled = quantity < item.minQty

  return (
    <>
      {modalIsOpen && (
        <MenuItemModal
          initialQty={quantity}
          showPricesExTax={showPricesExTax}
          taxAcronym={taxAcronym}
          mode="add"
          menuItem={item}
          vendor={vendor}
          onConfirmClick={onAddItemToBasketClick}
          onClose={toggleModalIsOpen}
          basketIsLoading={basketIsLoading}
          closeWithoutLoading={closeModalWithoutLoading}
          accountName={account?.name}
        />
      )}

      <Card
        sx={{ height: '280px', display: 'flex', flexDirection: 'column', position: 'relative' }}
        onClick={toggleModalIsOpen}
      >
        <MuiStack
          sx={{ flex: 1 }}
          minHeight="150px"
          height="150px"
          maxHeight="200px"
          spacing={1}
          direction={{ xs: 'column', sm: numberInCol > 1 ? 'column' : 'row', md: 'row' }}
          width="100%"
          justifyContent="space-between"
        >
          {item.image && (
            <Box
              position="relative"
              sx={{
                width: { xs: '100%', md: '350px', lg: '450px' },
                height: { xs: '150px', sm: numberInCol > 1 ? '150px' : '100%', md: '100%' },
                minHeight: { xs: 'unset', lg: '200px' },
              }}
            >
              {item.isPrivate && (
                <Chip
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Star sx={{ marginRight: 0.5 }} />
                      {`Private - ${account?.name}`}
                    </Box>
                  }
                  sx={{
                    position: 'absolute',
                    top: 5,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    backgroundColor: 'primary.light',
                  }}
                  color="primary"
                  size="small"
                />
              )}
              <Image
                src={item.image}
                quality={100}
                alt=""
                height={300}
                width={400}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
              {item.options.length > 0 ? (
                <Chip
                  sx={{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    transform: 'scale(0.8)',
                    backgroundColor: 'primary.light',
                  }}
                  color="primary"
                  size="small"
                  label="Options available"
                />
              ) : null}
            </Box>
          )}
          <MuiStack direction="column" width="100%">
            <MuiStack direction="row" justifyContent="space-between">
              <CardHeader title={item.name} />
            </MuiStack>
            <CardContent>
              {item.shortDescription && (
                <Typography color="text.secondary" variant="body2">
                  {item.shortDescription}
                </Typography>
              )}
            </CardContent>
          </MuiStack>
        </MuiStack>

        <Grid
          container
          sx={{ borderTopWidth: 0.5, borderTopColor: 'divider', borderTopStyle: 'solid' }}
          paddingX={1}
          paddingY={0.5}
          justifyContent="space-between"
          alignItems="center"
          mt="auto"
        >
          <Grid item xs={4} sm={numberInCol > 1 ? 6 : 3} md={numberInCol > 1 ? 5 : 3} lg={3} xl={2}>
            <DietaryListMui
              isComboItem={Boolean(item.isCombo)}
              maxToShow={3}
              dietaries={item.dietaries}
              allergens={[]}
            />
          </Grid>
          <Grid
            item
            xs={3}
            sm={numberInCol > 1 ? 6 : 4}
            md={numberInCol > 1 ? 7 : 4}
            lg={4}
            xl={3}
            display="flex"
            justifyContent={{
              xs: 'auto',
              sm: numberInCol > 1 ? 'right' : 'auto',
              md: 'space-between',
            }}
          >
            <Chip
              color="default"
              sx={{ border: 'none' }}
              variant="outlined"
              size="small"
              label={`Serves ${servesAmount}`}
              icon={<Person />}
            />
            {isLargeScreen && (
              <Chip
                label="More info"
                clickable
                color="primary"
                onClick={handleModalOpen}
                size="small"
                variant="outlined"
              />
            )}
          </Grid>
          <Grid
            item
            xs={2}
            sm={numberInCol > 1 ? 6 : 2}
            md={numberInCol > 1 ? 6 : 3}
            lg={3}
            xl={2}
            display="flex"
            flexDirection="row"
            justifyContent={{
              xs: 'center',
              sm: 'left',
              md: numberInCol > 1 ? 'left' : 'center',
              lg: 'center',
            }}
          >
            {isLargeScreen ? (
              <MuiStack alignItems="center" direction="row">
                <IconButton
                  disabled={quantity <= item.minQty}
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation()
                    setQuantity((prev) => Math.max(prev - 1, 1))
                  }}
                >
                  <RemoveCircle fontSize="small" />
                </IconButton>
                <Typography textAlign="center" minWidth="1.5em" variant="body2">
                  {quantity}
                </Typography>
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation()
                    setQuantity((prev) => prev + 1)
                  }}
                >
                  <AddCircle fontSize="small" />
                </IconButton>
              </MuiStack>
            ) : null}
            <IconButton
              disabled={quickAddDisabled}
              onClick={(e) => {
                e.stopPropagation()
                const optionsRequired = !reachedMinimumOptionRequirements({
                  menuItem: {
                    options: item.options as unknown as SelectedOptionItemValidation['option'][],
                  },
                  selectedOptions: new Map() as unknown as Map<
                    string,
                    Map<string, SelectedOptionItemValidation>
                  >,
                  topQuantity: quantity,
                })
                if (optionsRequired) toggleModalIsOpen()
                else handleAddItem()
              }}
            >
              <AddShoppingCart color={!quickAddDisabled ? 'primary' : 'disabled'} />
            </IconButton>
          </Grid>
          <Grid item xs={3} sm={numberInCol > 1 ? 6 : 3} md={numberInCol > 1 ? 6 : 2} lg={2} xl={2}>
            <Stack
              spacing={0.5}
              direction={{
                xs: 'column',
                sm: 'row',
                lg: numberInCol > 1 ? 'column' : 'row',
                xl: 'column',
              }}
              width="fit-content"
              marginLeft="auto"
              alignItems="center"
            >
              <Typography variant="body2" textAlign="right">
                {amountToPriceString(vendor.currency, price, true)} {priceCaveat}
              </Typography>
              <Typography variant="caption">(ex {taxAcronym})</Typography>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </>
  )
}

export default MenuItemCard
