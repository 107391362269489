
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[domain]/[lang]/office-catering/vendors/[permalink]",
      function () {
        return require("private-next-pages/[domain]/[lang]/office-catering/vendors/[permalink].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[domain]/[lang]/office-catering/vendors/[permalink]"])
      });
    }
  