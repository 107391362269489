/* eslint-disable no-confusing-arrow, react/prop-types, no-unused-vars */
import React from 'react'
import styled, { css } from 'styled-components'
import { colors, legacyColors } from '@vp/legacy/shared/theme'
// @ts-expect-error unknown
import chevronImage from '@vp/legacy/shared/img/icn-chevron-down-black.svg?url'
export const sizes: {
  [key: string]: number
} = {
  xxxl: 2080,
  xxl: 1600,
  xl: 1290,
  lg: 1200,
  md: 992,
  sm: 768,
  xs: 576,
}
export const heights = {
  headerMain: 64,
  buttonSmall: 32,
  buttonMedium: 40,
  buttonLarge: 48,
}
export const Context = {
  FEEDR: 'admin',
  MANAGER: 'manager',
  VENDOR: 'vendor',
  USER: 'user',
  KIOSK: 'kiosk',
  PUBLIC: 'public',
  VENDORADMIN: 'vendorAdmin',
} as const
// eslint-disable-next-line no-redeclare
export type Context = (typeof Context)[keyof typeof Context]
export const PromoContext = {
  MODAL: 'modal',
  CARD: 'card',
}
export const widths = {
  buttonMin: 200,
}
export const weights = {
  ultraLight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  black: 700,
}
export const fonts = {
  sourceSans: "'Montserrat', sans-serif",
  avenir: "'Montserrat', sans-serif",
  gilroy: "'Montserrat', sans-serif",
  montserrat: "'Montserrat', sans-serif",
  northwell: "'Northwell', sans-serif",
  nothwellSwash: "'NorthwellSwash'",
}

export enum ItemView {
  Grid = 'grid',
  List = 'list',
}

// use em in breakpoints to work properly cross-browser and support users
// changing their browsers font-size: https://zellwk.com/blog/media-query-units/
export const withMediaStyles = (widthPx: number, args: any[]) => {
  // @ts-expect-error TS spread error: https://github.com/microsoft/TypeScript/issues/28010
  const forwardCss = css(...args)
  return css`
    @media (max-width: ${widthPx / 16}em) {
      ${forwardCss}
    }
  `
}
/**
 * Iterate through the sizes and create a media template
 * @example
 *   ${media.xl`
 *     // styles here
 *   `}
 */
export const media: any = Object.keys(sizes).reduce((accumulator, label) => {
  const query = (...args: any[]) => withMediaStyles(sizes[label], args)
  return { ...accumulator, [label]: query }
}, {})
/**
 * Allows us to use a custom media query
 * Only use when required
 */
export const customMedia =
  (widthPx: number) =>
  (...args: any[]) =>
    withMediaStyles(widthPx, args)
export const ImgCover = css`
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
`
export const InputPlaceholder = styled.span<{
  fixed?: boolean
}>`
  display: block;
  pointer-events: none;
  z-index: 10;
  color: ${legacyColors.greyCopy};
  margin: 0 0 0.1em 0;
  ${(props: any) => (props.fixed ? 'font-size: 10pt !important;' : '')}
`
export const Input = styled.div<{
  placeholderFixed?: boolean
}>`
  position: relative;
  margin-bottom: 0.8em;

  &.has-value {
    .input-placeholder {
      transform: translateY(0);
      top: 0;
      font-size: 10pt;
      color: ${colors.black};
    }
  }

  ${(props: any) => (props.placeholderFixed ? 'padding-top: 15px;' : '')}

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="password"] {
    &:focus {
      outline: none;
      border-bottom-color: ${colors.avocado};

      /* TODO: Remove this and fix with .focus class */
      + .input-placeholder {
        transform: translateY(0);
        top: 0;
        font-size: 10pt;
        color: ${colors.avocado};
      }
    }
  }

  textarea {
    appearance: none;
    border: none;
    border: 1px solid ${colors.pepper_30};
    border-radius: 5px;
    padding: 8px;
    line-height: 150%;
    width: 100%;
    max-width: 600px;
    min-height: 100px;
    display: block;
    margin-top: 8px;

    &:focus {
      outline: 0;
      border-color: ${colors.avocado};

      + .input-placeholder {
        color: ${colors.avocado};
      }
    }
  }

  select {
    appearance: none;
    border: none;
    background: ${colors.white};
    border-bottom: 1px solid ${colors.pepper_30};
    padding: 8px 0;
    line-height: 150%;
    width: 100%;
    max-width: 600px;
    display: block;
    border-radius: 0;
    cursor: pointer;
    background-image: url(${chevronImage});
    background-size: 18px 11px;
    background-position: right 10px center;
    background-repeat: no-repeat;

    &:focus {
      outline: 0;
      border-bottom-color: ${colors.avocado};

      // TODO: Remove this and fix with .focus class
      + .input-placeholder {
        color: ${colors.avocado};
      }
    }
  }
`
export const ImageCaption = styled.p`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  margin: 0;
  padding: 0.75em 1em;
  color: ${colors.white};
  font-family: ${fonts.gilroy};
  font-size: 12pt !important;
  padding: 5px 10px;
  text-align: right !important;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.3));
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);

  strong {
    font-weight: bold;
  }

  a {
    color: ${colors.white};
    font-weight: bold;
    text-shadow: none;
  }
`
export const PlainButton = styled.button`
  appearance: none;
  padding: 0;
  border: 0;
  background: transparent;
  color: ${colors.avocado};
  cursor: pointer;
  transition: color 0.2s;
  font-family: ${fonts.gilroy};

  &:hover {
    color: ${colors.black};
  }

  &:focus {
    outline: none;
  }
`
export const PageSection = styled.div`
  margin-bottom: 50px;
`
type HeroProps = {
  white?: boolean
  overlay?: number
  image: string
  margin?: any
}
export const Hero = styled.div<HeroProps>`
  ${(props: any) => `
    background: linear-gradient(
      rgba(${props.white ? 255 : 0}, ${props.white ? 255 : 0}, ${props.white ? 255 : 0}, ${
        props.overlay || 0
      }),
      rgba(${props.white ? 255 : 0}, ${props.white ? 255 : 0}, ${props.white ? 255 : 0}, ${
        props.overlay || 0
      })
    ), url(${props.image})
  `};
  ${(props: any) => props.margin && 'margin-bottom: 50px;'}
  background-size: cover;
  position: relative;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${heights.headerMain}px 0;
  min-height: 300px;
  text-align: center;

  h1 {
    ${(props: any) => `
      color: ${props.white ? colors.black : colors.white};
      text-shadow: ${props.white ? `0px 0px 8px ${colors.white}` : 'none'};
      font-weight: ${props.white ? weights.regular : weights.ultraLight};
    `};
    font-family: ${fonts.gilroy};
    font-size: 36pt;
    text-align: center;
    margin: 0;

    strong {
      font-weight: bold;
    }
  }

  h2 {
    ${(props: any) => `
      color: ${props.white ? colors.black : colors.white};
      text-shadow: ${props.white ? `0px 0px 8px ${colors.white}` : 'none'};
      font-weight: ${props.white ? weights.regular : weights.ultraLight};
    `};
    font-family: ${fonts.gilroy};
    font-size: 22pt;
    text-align: center;

    strong {
      font-weight: bold;
    }
  }

  p {
    ${(props: any) => `
      color: ${props.white ? colors.black : colors.white};
      text-shadow: ${props.white ? `0px 0px 8px ${colors.white}` : 'none'};
      font-weight: ${props.white ? weights.regular : weights.ultraLight};
    `};
    font-family: ${fonts.gilroy};
    font-size: 14pt;
    text-align: center;
  }

  img {
    max-width: 100px;
    max-height: 100px;
  }
`
export const InfoBanner = styled.div`
  border-top: 8px solid ${colors.avocado};
  border-bottom: 1px solid ${legacyColors.greyCopy};
  padding: 30px 0;

  .stat {
    color: ${colors.avocado};
    font-size: 22pt;
    font-family: ${fonts.gilroy};
    text-align: center;
    margin: 0;
  }

  .supplement {
    font-size: 14pt;
    text-align: center;
    margin: 0;
  }
`
export const SimpleBox = styled.div<{
  padding?: string
  background?: string
}>`
  padding: ${({ padding }) => padding || '25px 20px 20px'};
  background: ${({ background }) => background || colors.white};
  border-radius: 8px;
  box-shadow: 0 2px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
`
interface LayoutElementProps {
  className?: string
  // eslint-disable-next-line react/no-unused-prop-types
  testId?: string
  style?: object
  children?: React.ReactNode
}
export const Container: React.FC<LayoutElementProps> = ({ className, testId, style, children }) => (
  <div className={`container ${className ?? ''}`} data-testid={testId} style={style}>
    {children}
  </div>
)
export const ContainerWide = styled(Container)`
  max-width: 1440px !important;
`
export const ContainerNarrow = styled(Container)`
  max-width: 960px !important;
`
export const ContainerFull = styled(Container)`
  max-width: 100% !important;
`
export const Row: React.FC<LayoutElementProps> = ({ className, testId, style, children }) => (
  <div className={`row ${className ?? ''}`} data-testid={testId} style={style}>
    {children}
  </div>
)
interface ColProps extends LayoutElementProps {
  size?: number | string
}
export const Col: React.FC<ColProps> = ({ size, className, style, children }) => (
  <div className={`col ${size ?? ''} ${className ?? ''}`} style={style}>
    {children}
  </div>
)
