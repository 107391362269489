import React from 'react'

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75 60">
      <path
        fillRule="evenodd"
        d="M43.962-2.999a2.651 2.651 0 00-1.777.744l-3.657 3.473c-1.454 1.382-2.273 3.257-2.273 5.21v1.095l-16.89 4.375-14.9-14.153L.81 1.218 73.217 70l3.657-3.474-15.9-15.103c2.352-3.693 3.727-8.005 3.727-12.638V28.96c0-1.356-1.158-2.456-2.586-2.456H34.74l-5.172-4.913h33.325c2.426 0 4.394-1.872 4.394-4.179 0-1.884-1.329-3.534-3.243-4.03L41.427 7.523V6.429c0-.65.272-1.276.758-1.737l3.656-3.474c.765-.706.995-1.786.58-2.719-.416-.933-1.392-1.528-2.459-1.498zM15.566 26.502c-1.427 0-2.586 1.1-2.586 2.456v9.826c0 12.74 10.208 23.216 23.28 24.445-.003.04-.005.08-.005.12 0 1.357 1.157 2.457 2.585 2.457 1.429 0 2.586-1.1 2.586-2.457 0-.045-.002-.09-.005-.134a26.563 26.563 0 0012.633-4.596l-33.81-32.117h-4.678z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default Icon
