/* eslint-disable import/prefer-default-export */
/**
 * Formats a value to a certain number of decimal places.
 * @param {number|string} input
 * @param {number} decimals
 * @returns {string}
 */
export const numberFormat = (input = 0, decimals = 2, commaSeparate = true) => {
  let value = input
  if (typeof input === 'string') {
    // @ts-expect-error unknown
    value = input.replace(',', '')
  }
  // undefined or null will always default to zero
  let number = Number(Math.abs(value))
  if (!isFinite(number)) number = 0
  // https://www.jacklmoore.com/notes/rounding-in-javascript/
  // @ts-expect-error unknown
  const roundedNumber = Number(`${Math.round(`${number}e${decimals}`)}e-${decimals}`)
  const toDecimals = roundedNumber.toFixed(decimals)
  if (!commaSeparate) {
    return input < 0 ? `-${toDecimals}` : toDecimals
  }
  const asString = toDecimals.split(/(?=(?:\d{3})+(?:\.|$))/g).join(',')
  const sign = input < 0 ? '-' : ''
  return `${sign}${asString}`
}
/**
 * Formats a number to 2 decimal places or returns an empty string
 * @param {number|string} input
 * @returns {string}
 */
export const priceFormat = (price: any, commaSeparate = true) => {
  let value = price
  if (typeof price === 'string') {
    value = price.replace(',', '')
  }
  return !isNaN(parseFloat(value)) ? numberFormat(value, 2, commaSeparate) : ''
}
export const priceStringToInt = (priceString: any) => {
  if (!priceString || typeof priceString !== 'string') return NaN
  const [pounds, , pence] = priceString.split(/(\.)/)
  return Number(pounds) * 100 + (Number(pence) || 0)
}
export const roundTo2DP = (number: any) => {
  const precise = Number(number.toPrecision(12))
  return Number(precise.toFixed(2))
}
export const preventInvalidChar = (e: any) => ['.'].includes(e.key) && e.preventDefault()
