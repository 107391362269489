import {
  MenuItemOptionFragment,
  MenuOptionItemFragment,
  MenuOptionMenuItemFragment,
} from '@/generated/graphql'
import { areFieldsNotNil, isNotNil, Replace, RequireFields } from '../utils/typing'

export type MenuOptionItem = Replace<
  MenuOptionItemFragment,
  { item: RequireFields<MenuOptionMenuItemFragment, 'name'> }
>
export type MenuItemOption = Replace<MenuItemOptionFragment, { items: MenuOptionItem[] }>

export const createMenuItemOption = (option: MenuItemOptionFragment): MenuItemOption => ({
  ...option,
  items: (option.items || [])
    .filter(isNotNil)
    .filter(areFieldsNotNil(['item']))
    .filter((i) => i.item.isActive)
    .flatMap((optionItem) =>
      optionItem.item.name
        ? [
            {
              ...optionItem,
              price: optionItem.priceCustomerFacing,
              priceExTax: optionItem.priceCustomerFacingExTax,
              item: {
                ...optionItem.item,
                name: optionItem.item.name,
              },
            },
          ]
        : [],
    ),
})
