import NextImage, { ImageLoader, ImageProps } from 'next/image'
import React from 'react'
import appVariables from '@/config'

interface ImageUrlProps {
  src: string
  width: number
  quality?: number
}

const normalizeSrc = (src: string) => {
  return src.startsWith('/') ? src.slice(1) : src
}

const optimisedDomains = [
  'teamfeedr.com',
  'feedr.co',
  'img.caterdesk-static.com',
  'img.teamfeedr.com',
]
export const getImageUrl = ({ src, width, quality }: ImageUrlProps): string => {
  if (!appVariables.CLOUDFLARE_OPTIMISE_IMAGE) {
    return src
  }
  let limitedWidth = width
  if (width > 1500) {
    limitedWidth = 1500
  }

  const params = ['format=auto', `width=${limitedWidth}`]
  if (quality) {
    params.push(`quality=${quality}`)
  }
  const paramsString = params.join(',')

  if (src.startsWith('https://')) {
    if (!optimisedDomains.some((domain) => src.includes(domain))) return src

    const url = new URL(src)
    url.pathname = `/cdn-cgi/image/${paramsString}${url.pathname}`
    return url.toString()
  }

  return `/cdn-cgi/image/${paramsString}/${normalizeSrc(src)}`
}

export const imageLoader: ImageLoader = ({ src, width, quality }) => {
  return getImageUrl({ src, width, quality })
}

const Image: React.FC<ImageProps> = (props) => {
  return (
    <NextImage
      loader={appVariables.CLOUDFLARE_OPTIMISE_IMAGE ? imageLoader : undefined}
      unoptimized={!appVariables.CLOUDFLARE_OPTIMISE_IMAGE}
      {...props}
    />
  )
}

export default Image
