import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

type Properties = {
  selectedCountry: string
}

type Actions = {
  setSelectedCountry: (countryCode: string) => void
}

type AdminGlobalState = Properties & Actions

const defaultProperties: Properties = {
  selectedCountry: 'GB',
}

export const useAdminGlobalState = create<AdminGlobalState>()(
  devtools((set) => ({
    ...defaultProperties,
    setSelectedCountry: (countryCode) => set(() => ({ selectedCountry: countryCode })),
  })),
)
