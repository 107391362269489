import styled from 'styled-components'
import { mobileMediaQuery } from '@teamfeedr/ui--theme'
import { colors } from '@/constants/theme'
import { getImageUrl } from '../image'

type HeaderProps = {
  image: string
  backgroundSize: string
}

export const Header = styled.div<HeaderProps>`
  background: url(${(p) => (p.image ? getImageUrl({ src: p.image, width: 1000 }) : '')});
  background-position: right center;
  background-size: ${(p) => p.backgroundSize};

  @media ${mobileMediaQuery} {
    background: none;
  }
`

export const HeaderWrapper = styled.div`
  position: relative;
`

export const HeaderImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  object-fit: cover;
  height: 100%;
  z-index: -1;
`

export const Veil = styled.div`
  background: linear-gradient(270deg, rgba(250, 250, 250, 0.0001) -0.94%, #fafafa 40%);
  padding: 25px 36px;

  @media ${mobileMediaQuery} {
    background: none;
    padding: 0;
  }
`

export const BackLink = styled.div`
  margin-bottom: 40px;

  @media ${mobileMediaQuery} {
    margin-bottom: 16px;
    margin-left: 16px;
  }
`

export const Logo = styled.img`
  border-radius: 100%;
  height: 115px;
  width: 115px;

  @media ${mobileMediaQuery} {
    position: absolute;
    left: 16px;
    top: -40px;
    width: 78px;
    height: 78px;
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;

  @media ${mobileMediaQuery} {
    flex-direction: column;
    border-bottom: 1px solid ${(p) => p.theme.colors.background_dark};
  }
`

export const Details = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`

export const Info = styled.div`
  padding-left: 16px;
  width: 100%;

  @media ${mobileMediaQuery} {
    padding: 8px 16px 16px 110px;
  }
`

export const Badges = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px 16px;
  margin-top: 4px;
`

export const Title = styled.h1`
  font-weight: ${(p) => p.theme.fontWeights.medium};
  font-size: ${(p) => p.theme.fontSizes.xxxl};
  color: ${(p) => p.theme.colors.text_heading};
  line-height: 46px;
  margin: 3px 0;

  @media ${mobileMediaQuery} {
    font-size: ${(p) => p.theme.fontSizes.l};
    color: ${(p) => p.theme.colors.text_contrast};
    line-height: 26px;
    position: absolute;
    left: 110px;
    bottom: 100%;
  }
`

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 25vh;
`

export const BlackVeil = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    1.96deg,
    rgba(63, 65, 68, 0.72) 32.26%,
    rgba(250, 250, 250, 0) 99.85%,
    rgba(63, 65, 68, 0.0001) 99.86%
  );
`

export const MobileImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const Keywords = styled.p`
  font-weight: ${(p) => p.theme.fontWeights.medium};
  font-size: ${(p) => p.theme.fontSizes.base};
  color: ${(p) => p.theme.colors.text};
  line-height: 22px;
  margin: 3px 8px 3px 0;
  display: inline;

  @media ${mobileMediaQuery} {
    font-size: ${(p) => p.theme.fontSizes.s};
    line-height: 25px;
  }
`

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
`

export const ModalLogo = styled.img`
  border-radius: 100%;
  height: 38px;
  width: 38px;
  margin-right: 10px;
`

export const HandpickedBox = styled.div`
  background-color: ${(p) => p.theme.colors.error_lightest};
  width: 100%;
  padding: 16px;
  margin: 16px 0;
  color: ${(p) => p.theme.colors.error};
  font-size: ${(p) => p.theme.fontSizes.xs};
  line-height: 23px;
  border-radius: 8px;
`

export const SourcingBox = styled.div`
  background-color: ${(p) => p.theme.colors.primary_lightest};
  width: 100%;
  padding: 16px;
  margin: 16px 0;
  color: ${(p) => p.theme.colors.primary};
  font-size: ${(p) => p.theme.fontSizes.xs};
  line-height: 23px;
  border-radius: 8px;
`

export const BoxHeader = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${(p) => p.theme.fontWeights.medium};
  font-size: ${(p) => p.theme.fontSizes.s};
  line-height: 20px;
  margin-bottom: 8px;

  span {
    margin-right: 8px;
  }
`

export const GreenText = styled.span`
  font-size: 80px;
  color: ${() => colors.dragonFruit.main};
  line-height: 1.5em;
  margin: -0.75em 0;
  display: inline-block;
`
