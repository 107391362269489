import React from 'react'
import { Avatar, Stack, Tooltip, Typography } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'

type Props = {
  infoAsTooltip?: boolean
}

const AllergensComboInfo: React.FC<Props> = ({ infoAsTooltip = false }) => {
  const info = 'Allergens for this item may vary based on choice of options or add ons'
  return (
    <Stack alignItems="center" alignContent="center" spacing={1} direction="row">
      <Tooltip title={info}>
        <Avatar variant="circular" sx={{ bgcolor: 'info.main', width: 26, height: 26 }}>
          <InfoOutlined fontSize="large" />
        </Avatar>
      </Tooltip>
      {!infoAsTooltip ? (
        <Typography variant="body2" color="info.main">
          {info}
        </Typography>
      ) : null}
    </Stack>
  )
}

export default AllergensComboInfo
