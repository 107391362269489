import React from 'react'
import { match } from 'ts-pattern'
import { MenuItemOptionFragment, MenuItemOptionType } from '@/generated/graphql'
import { OptionGuideText } from './index.styles'

const OptionGuidanceText: React.FC<{
  option: MenuItemOptionFragment
  quantity: number
}> = ({ option, quantity }) => {
  const { minQty, maxQty, qtyPerPerson, optionType } = option || {}

  const text = match(optionType)
    .with(MenuItemOptionType.Ticks, () => {
      if (!minQty && maxQty) {
        return `Choose up to ${maxQty} ${maxQty > 1 ? 'types' : 'type'}`
      }
      if (minQty && minQty === maxQty) {
        return `You must select ${minQty} ${minQty > 1 ? 'types' : 'type'}`
      }
      if (minQty && !maxQty) {
        return `You must select at least ${minQty} ${minQty > 1 ? 'types' : 'type'}`
      }
      if (minQty && maxQty) {
        return `Choose at least ${minQty} - ${maxQty} types`
      }
      return null
    })
    .with(MenuItemOptionType.Numbers, () => {
      if (qtyPerPerson) {
        return `Select ${quantity * qtyPerPerson} item(s) in total`
      }
      if (!minQty && maxQty) {
        return `Choose up to ${maxQty} ${maxQty > 1 ? 'different types' : 'type'}`
      }
      if (minQty && minQty === maxQty) {
        return `Choose ${minQty} ${minQty > 1 ? 'different types' : 'type'}`
      }
      if (minQty && !maxQty) {
        return `Choose at least ${minQty} ${minQty > 1 ? 'different types' : 'type'}`
      }
      if (minQty && maxQty) {
        return `Choose ${minQty} - ${maxQty} different types`
      }
      return null
    })
    .otherwise(() => null)

  return <OptionGuideText>{text}</OptionGuideText>
}

export default OptionGuidanceText
