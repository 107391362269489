import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, Stack, Collapse, Menu, ToggleButton as ToggleButtonMui } from '@mui/material'
import ToggleButton, { ToggleButtonsContainer } from '@teamfeedr/ui--toggle-button'
import { main } from '@teamfeedr/ui--theme'
import { Allergen, Dietary } from '@/generated/graphql'
import { MenuItemFilter, MenuItemFilterSection } from '../../domain/menu-item-filter'
import { Add, Remove } from '@mui/icons-material'
import useGmFiltersStore from '../../helpers/gmFiltersStore'

const ToggleButtons = styled.div<{ hasFiltersSelected: boolean }>`
  background: ${main.colors.canvas};
  border-radius: 5px;
  padding: 16px;
  margin-top: 8px;
  width: 100%;

  ${(p) =>
    !p.hasFiltersSelected &&
    `
      input:not([disabled])[type='checkbox'] ~ div {
        opacity: 1;
      }
    `}
`

type Props = {
  type: MenuItemFilterSection
  label: string
  filters: MenuItemFilter[]
  selectedFilters: MenuItemFilter[]
  selectedDietaries?: MenuItemFilter[]
  hasFiltersSelected: boolean
  asRow: boolean
  visibility: boolean
}

const FilterSection = ({
  type,
  label,
  filters,
  selectedFilters,
  selectedDietaries,
  hasFiltersSelected,
  asRow,
  visibility,
}: Props) => {
  const { setDietaryPreferences } = useGmFiltersStore()
  const [expanded, setExpanded] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const emptyFilter = filters.every((filter) => filter.count === 0)
  if (!visibility || emptyFilter) return

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as Dietary | Allergen
    const filter = filters.find((f) => f.value === value)
    const newFilters =
      event.target.checked && filter
        ? [...selectedFilters, filter]
        : selectedFilters.filter((f) => f.value !== value)
    setDietaryPreferences(type, newFilters)
  }

  const handleClick = () => {
    setDietaryPreferences(type, [])
    const toggles = document.getElementsByName(type)
    toggles.forEach((toggle) => ((toggle as HTMLInputElement).checked = false))
  }

  const filterSectionTitle = `${label} ${
    selectedFilters.length > 0 ? `(${selectedFilters.length})` : ''
  }`

  const toggleOn = (value: Dietary | Allergen) => {
    const nuts = Boolean(
      value === Allergen.Peanuts &&
        selectedDietaries?.map((d) => d.value).includes(Dietary.ContainsNuts),
    )
    return selectedFilters.map((f) => f.value).includes(value) || nuts
  }

  return (
    <Stack spacing={1} width={{ xs: '100%', md: asRow ? '250px' : '100%' }}>
      <ToggleButtonMui
        value=""
        selected={selectedFilters.length > 0}
        size={asRow ? 'large' : 'medium'}
        color="primary"
        sx={{ width: '100%', justifyContent: 'space-between', backgroundColor: 'background.paper' }}
        onClick={(e) => {
          setAnchorEl(e.currentTarget)
          setExpanded((prev) => !prev)
        }}
      >
        {filterSectionTitle}
        {!expanded || !open ? <Add /> : <Remove />}
      </ToggleButtonMui>
      {asRow ? (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <Stack paddingBottom={1}>
            <Button
              onClick={() => handleClick()}
              disabled={selectedFilters.length === 0}
              sx={{ marginTop: -1, marginLeft: 'auto' }}
            >
              Clear
            </Button>
            <ToggleButtons hasFiltersSelected={hasFiltersSelected}>
              <ToggleButtonsContainer onChange={handleChange} className="toggles">
                {filters.map((filter) => (
                  <div key={filter.value}>
                    {filter.count >= 1 ? (
                      <ToggleButton
                        label={filter.label}
                        description={filter.description}
                        value={filter.value}
                        name={type}
                        qty={filter.count}
                        backgroundColor={filter.color}
                        icon={filter.icon}
                        toggleOn={toggleOn(filter.value)}
                      />
                    ) : null}
                  </div>
                ))}
              </ToggleButtonsContainer>
            </ToggleButtons>
          </Stack>
        </Menu>
      ) : (
        <Collapse in={expanded} timeout="auto">
          <Stack paddingBottom={1}>
            <Button
              onClick={() => handleClick()}
              disabled={selectedFilters.length === 0}
              sx={{ marginTop: -1, marginLeft: 'auto' }}
            >
              Clear
            </Button>
            <ToggleButtons hasFiltersSelected={hasFiltersSelected}>
              <ToggleButtonsContainer onChange={handleChange} className="toggles">
                {filters.map((filter) => (
                  <div key={filter.value}>
                    {filter.count >= 1 ? (
                      <ToggleButton
                        label={filter.label}
                        description={filter.description}
                        value={filter.value}
                        name={type}
                        qty={filter.count}
                        backgroundColor={filter.color}
                        icon={filter.icon}
                        toggleOn={toggleOn(filter.value)}
                      />
                    ) : null}
                  </div>
                ))}
              </ToggleButtonsContainer>
            </ToggleButtons>
          </Stack>
        </Collapse>
      )}
    </Stack>
  )
}

export default FilterSection
