import React from 'react'

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path d="M27 3s-3 0-4.44 1.32C21 6 21.48 7.8 21.84 8.16c.36.24 2.28.84 3.96-.84C27 6 27 3 27 3zm-7.08 1.32c-1.32.12-3.36 1.44-3.72 3.12-.6 2.16.72 3.6 1.08 3.72.36.12 1.68-.72 2.28-2.4.84-2.4.48-4.44.36-4.44zm-5.654 3.84s-2.4 1.32-2.4 3.96c0 2.64 1.079 3.24 1.679 3.24.6 0 1.92-1.2 1.92-3.12 0-2.76-1.2-4.08-1.2-4.08zM24.359 9.9c-.81 0-1.919.12-3.119.54-1.8.72-2.52 1.92-2.4 2.28.12.36 1.56 1.68 3.72 1.08 1.8-.48 3-2.4 3.12-3.72 0-.06-.51-.18-1.32-.18zM9.834 12.71s-2.4 1.322-2.4 3.962 1.08 3.238 1.68 3.238c.6 0 1.92-1.199 1.92-3.119 0-2.76-1.2-4.08-1.2-4.08zm7.877 1.843c-1.92 0-3.12 1.32-3.12 1.92 0 .6.6 1.68 3.24 1.68s3.96-2.401 3.96-2.401-1.32-1.2-4.08-1.2zM5.4 17.16S3 18.48 3 21.12c0 2.64 1.08 3.24 1.68 3.24.6 0 1.92-1.2 1.92-3.12 0-2.76-1.2-4.08-1.2-4.08zm7.831 1.75c-1.92 0-3.12 1.32-3.12 1.92 0 .6.6 1.68 3.24 1.68s3.96-2.4 3.96-2.4-1.32-1.2-4.08-1.2zM8.881 23.4c-1.92 0-3.121 1.32-3.121 1.92 0 .6.6 1.68 3.24 1.68s3.96-2.4 3.96-2.4-1.32-1.2-4.08-1.2z" />
    </svg>
  )
}

export default Icon
