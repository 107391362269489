import React from 'react'

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75 60">
      <path
        fillRule="evenodd"
        d="M4.525-3L.768.606 75.159 72l3.757-3.606-27.673-26.558c4.439-1.584 6.699-5.419 6.699-5.419s-3.507-3.057-10.84-3.057c-1.488 0-2.803.29-3.928.732l-2.865-2.75a9.542 9.542 0 00.804-3.869c0-7.037-3.186-10.403-3.186-10.403s-4.057 2.205-5.682 6.534L4.525-3zm67.098 7S63.7 4 59.896 7.383c-4.12 4.306-2.854 8.918-1.903 9.84.95.616 6.025 2.154 10.462-2.152C71.625 11.69 71.623 4 71.623 4zM53.445 8c-3.392.298-8.636 3.571-9.56 7.74-1.542 5.36 1.85 8.934 2.775 9.232.925.298 4.32-1.788 5.861-5.956C54.68 13.06 53.753 8 53.445 8zm11.639 14c-2.094 0-4.96.294-8.063 1.33-4.653 1.775-6.515 4.738-6.205 5.626.31.888 4.035 4.144 9.618 2.664 4.653-1.184 7.753-5.921 8.063-9.177 0-.148-1.32-.443-3.413-.443zm-40.25 8c-1.834 1.585-4.27 4.484-4.27 8.705 0 6.758 2.813 8.295 4.377 8.295 1.563 0 5.001-3.07 5.001-7.985 0-1.7-.19-3.173-.463-4.45L24.834 30zm-10.52 10s-6.253 3.298-6.253 9.898C8.06 56.5 10.873 58 12.437 58c1.563 0 5.001-2.998 5.001-7.799 0-6.9-3.124-10.201-3.124-10.201zm20.85 5c-5.135 0-8.348 3.3-8.348 4.8s1.606 4.2 8.667 4.2c4.373 0 7.38-2.296 9.047-4.048l-4.842-4.527A23.868 23.868 0 0035.164 45zm-11.68 11c-5 0-8.129 3.3-8.129 4.8s1.564 4.2 8.44 4.2c6.876 0 10.316-6.002 10.316-6.002S30.673 56 23.485 56z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default Icon
