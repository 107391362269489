import _ from 'lodash'
import { MenuItem } from './menu-item'
import { MenuVendorCategory } from './menu-vendor'

export type MenuCategory = {
  id: string
  name: string
  items: MenuItem[]
}

export const createMenuCategories = (
  items: MenuItem[],
  vendorCategories: MenuVendorCategory[],
  categoriesWithCounts: Record<string, number>,
): MenuCategory[] => {
  const vendorCategoryIds = vendorCategories.map((vc) => vc.id)

  const itemsByCategory = _.chain(items)
    .flatMap((item) => {
      let categoryIds: (string | number)[] =
        // Filter out categories that are no longer available for the vendor
        // This can happen if a vendor removes a category and does not reassign existing items to a new category
        item.categoryIdArray?.filter((categoryId) =>
          vendorCategoryIds.includes(categoryId.toString()),
        ) || []
      if (categoryIds.length <= 0) {
        categoryIds = ['everything']
      }

      return categoryIds.map((categoryId) => ({
        categoryId: categoryId?.toString(),
        item,
      }))
    })
    .groupBy('categoryId')
    .mapValues((groupedItems) => ({
      items: groupedItems.map((gi) => gi.item),
    }))
    .value()
  return _.chain([
    ...vendorCategories,
    {
      id: 'everything',
      name: 'Everything Else',
      position: 9999,
    },
  ])
    .sortBy(({ position }) => position)
    .filter(({ id }) => Boolean(categoriesWithCounts[id]))
    .map(({ id, name }) => ({
      id,
      name,
      items: itemsByCategory[id]?.items ?? [],
    }))
    .value()
}
