import React, { useState } from 'react'
import styled from 'styled-components'
import Select, { SelectProps } from '@teamfeedr/ui--select'
import Input from '@teamfeedr/ui--input'
import Modal from '@teamfeedr/ui--modal'
import Filters from './filters'
import Box from '@teamfeedr/ui--box'
import { Text, Title } from '@teamfeedr/ui--typography'
import { main } from '@teamfeedr/ui--theme'
import { MenuFilterCategory, SelectedMenuFilterCategory } from '../../domain/menu-category-filter'
import { MenuItemFilterSections } from '../../domain/menu-item-filter'
import useAllergenInfoText from '../../helpers/useAllergenInfoText'
import useGmFiltersStore from '../../helpers/gmFiltersStore'

const FilterInputs = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 0;
  gap: 8px;
  border-bottom: 1px solid ${main.colors.background_dark};

  > div {
    flex-basis: 50%;
  }
`

type Props = {
  categories: MenuFilterCategory[]
  filterSections: MenuItemFilterSections
  selectedCategory: SelectedMenuFilterCategory
  onCategoryChange: (category: string) => void
}

const MobileFilters = ({
  categories,
  filterSections,
  selectedCategory,
  onCategoryChange,
}: Props) => {
  const { goals, dietaries, allergens } = useGmFiltersStore()
  const allergenInfoText = useAllergenInfoText()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const categoryOptions: SelectProps['options'] = categories.map((category) => ({
    label: category.name,
    value: category.name,
  }))

  const selectedFiltersCount = goals.length + dietaries.length + allergens.length

  const handleCategoryChange: SelectProps['onChange'] = (selectedOption) =>
    selectedOption && onCategoryChange(selectedOption.value)
  const filterCount = selectedFiltersCount > 0 ? `(${selectedFiltersCount})` : ''

  return (
    <>
      <FilterInputs>
        <Select
          placeholder="Categories"
          value={{ label: selectedCategory.name, value: selectedCategory.name }}
          options={categoryOptions}
          onChange={handleCategoryChange}
          isSearchable={false}
        />
        <Input
          readOnly
          value={`Filters ${filterCount}`}
          name="filters"
          onClick={() => setModalIsOpen(true)}
        />
      </FilterInputs>

      {modalIsOpen && (
        <Modal.Wrapper
          header="Filter by"
          body={
            <Box padding={{ x: 16, y: 16 }} backgroundColor="background">
              <Filters asRow={false} filterSections={filterSections} />
              <Box padding={{ x: 0, y: 24 }}>
                <Title size="base">Allergen Notes</Title>
                <Text size="xxs">{allergenInfoText}</Text>
              </Box>
            </Box>
          }
          onClose={() => setModalIsOpen(false)}
        />
      )}
    </>
  )
}

export default MobileFilters
