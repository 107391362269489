import React from 'react'

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path d="M16.98-.01a1 1 0 00-.687.303l-1.414 1.414A3 3 0 0014 3.828v.445L5.254 6.658A1.701 1.701 0 005.7 10H24.3a1.702 1.702 0 00.447-3.342L16 4.273v-.445a1 1 0 01.293-.707l1.414-1.414A1 1 0 0016.98-.01zM6 12a1 1 0 00-1 1v4c0 5.186 3.947 9.45 9.002 9.951A1 1 0 0014 27a1 1 0 001 1 1 1 0 001-1 1 1 0 00-.002-.049C21.053 26.451 25 22.186 25 17v-4a1 1 0 00-1-1H6z" />
    </svg>
  )
}

export default Icon
