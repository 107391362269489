import React from 'react'
import { useRouter } from '@/components/Link'

// eslint-disable-next-line react/display-name
export const withRouter = (Component: React.FC) => (props: any) => {
  const router = useRouter()

  if (router.isReady || props.ssg) {
    return <Component {...props} />
  }

  return null
}
