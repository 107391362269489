import React from 'react'

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 50">
      <path d="M45 0c-9 0-18 4.497-18 15.738 0 2.575.378 4.576 1.064 6.147a55.876 55.876 0 00-1.23 3.163 27.042 27.042 0 00-3.042-4.98 34.927 34.927 0 00-1.353-1.652c.034-.272.061-.553.061-.807 0-9.362-7.497-13.112-15.003-13.112H0v5.62C0 21.36 7.497 26.98 13.122 26.98c1.134 0 2.126-.194 3.042-.466-1.539-2.257-4.149-5.593-7.787-8.536a2.257 2.257 0 01-.335-3.17 2.256 2.256 0 013.163-.333c3.208 2.608 6.453 5.7 8.122 7.307.29.341.624.674.887 1.018 2.867 3.732 4.545 7.842 4.536 13.173V36l2.25-.027h2.25v-.027c-.036-3.345 1.195-8.93 3.982-14.5a44.87 44.87 0 012.32-4.073c1.591-2.258 3.27-4.542 4.824-6.095a2.245 2.245 0 113.19 3.161c-3.716 3.74-6.326 7.86-7.926 12.401.826.095 1.697.14 2.61.14C45 26.98 54 20.235 54 6.745V0h-9z" />
    </svg>
  )
}

export default Icon
