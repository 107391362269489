import React from 'react'

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path d="M24.217 3C22.072 3 17.515 5.822 17 10.5c-3.798.415-6.405 3.412-7 6.5-1.163.241-7 2.371-7 8 0 1.121 1.927 2 5.353 2 4.435 0 6.774-3.097 6.774-3.097 6.506-.439 7.968-5.093 8.1-7.188 0 0 3.843-2.362 3.843-6.967C27.07 5.96 25.922 3 24.217 3zM9.581 24.005c-.975.696-2.178.682-2.686-.031-.508-.713-.129-1.854.846-2.55.975-.695 2.178-.681 2.686.031.508.713.129 1.854-.846 2.55zm9.293-5.131c-1.294 1.294-3.182 1.503-4.217.469-1.035-1.035-.825-2.923.469-4.217 1.294-1.294 3.182-1.503 4.217-.469s.825 2.923-.469 4.217zm5.198-9.096c-.51 1.33-1.718 2.108-2.697 1.738-.979-.37-1.36-1.748-.85-3.078.51-1.33 1.718-2.108 2.697-1.738s1.36 1.748.85 3.078z" />
    </svg>
  )
}

export default Icon
