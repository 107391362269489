/* eslint-disable camelcase */
import React from 'react'
import styled from 'styled-components'
import { match, P } from 'ts-pattern'
import Modal from '@teamfeedr/ui--modal'
import { main, mobileMediaQuery } from '@teamfeedr/ui--theme'
import { Text, TextExtraExtraSmall, TextExtraSmall } from '@teamfeedr/ui--typography'
import Box from '@teamfeedr/ui--box'
import Stack from '@teamfeedr/ui--stack'
import { MenuVendor } from '../../domain/menu-vendor'
import { MenuItem } from '../../domain/menu-item'
import { SectionTitle } from './index.styles'
import { useMedia } from 'react-use'
import dynamic from 'next/dynamic'

const PieChart = dynamic(() => import('@teamfeedr/ui--pie-chart'), {
  ssr: false,
})

type Props = {
  vendor: MenuVendor
  item: MenuItem
}

const Serves = styled.span`
  color: ${main.colors.text};
`

const Macro = styled(Box)`
  width: 100%;
  border-radius: 5px;
  padding: 8px 16px;
  background: ${main.colors.background_light};
`

export const NutritionalTab: React.FC<Props> = ({ vendor, item }) => {
  const isMobileScreen = useMedia(mobileMediaQuery, false)

  const {
    macros_kcal,
    macros_protein,
    macros_carbs,
    macros_fat,
    macros_sugar,
    macros_saturates,
    macros_fibre,
    macros_salt,
  } = item

  const hasNutritionalData = Boolean(
    macros_kcal ||
      macros_protein ||
      macros_carbs ||
      macros_fat ||
      macros_sugar ||
      macros_saturates ||
      macros_fibre ||
      macros_salt,
  )

  const macros = [
    {
      label: 'kcal',
      value: macros_kcal,
    },
    {
      label: 'protein',
      value: macros_protein,
    },
    {
      label: 'carbs',
      value: macros_carbs,
    },
    {
      label: 'fat',
      value: macros_fat,
    },
    {
      label: 'sugar',
      value: macros_sugar,
    },
    {
      label: 'sat. fat',
      value: macros_saturates,
    },
    {
      label: 'fibre',
      value: macros_fibre,
    },
    {
      label: 'salt',
      value: macros_salt,
    },
  ]

  return (
    <Modal.Section>
      <Stack direction="vertical" spacing={16} alignItems="stretch">
        {match(hasNutritionalData)
          .with(true, () => (
            <>
              <Box>
                <SectionTitle color={main.colors.text_heading}>
                  Nutritional information
                </SectionTitle>
                <Box padding={{ y: 4 }}>
                  <TextExtraSmall style={{ lineHeight: '24px' }}>
                    All information provided by {vendor.companyName}
                  </TextExtraSmall>
                  <TextExtraSmall style={{ lineHeight: '24px' }}>
                    Adults need around 2000 calories per day
                  </TextExtraSmall>
                </Box>
              </Box>

              {match(item)
                .with(
                  {
                    macros_protein: P.number,
                    macros_carbs: P.number,
                    macros_fat: P.number,
                    macros_kcal: P.number,
                  },
                  ({ macros_protein, macros_carbs, macros_fat, macros_kcal }) => {
                    const pieChartData = [
                      {
                        value: macros_protein,
                        label: 'Protein',
                        unit: 'g',
                        className: 'protein',
                        color: 'primary' as const,
                      },
                      {
                        value: macros_carbs,
                        label: 'Carbohydrates',
                        unit: 'g',
                        className: 'carbs',
                        color: 'info' as const,
                      },
                      {
                        value: macros_fat,
                        label: 'Fat',
                        unit: 'g',
                        className: 'fat',
                        color: 'special' as const,
                      },
                    ]
                    const centralData = {
                      value: macros_kcal,
                      label: 'Calories',
                    }
                    return <PieChart data={pieChartData} centralData={centralData} />
                  },
                )
                .otherwise(() => null)}

              <SectionTitle color={main.colors.text_heading}>
                Base dish <Serves>(Serves 1)</Serves>
              </SectionTitle>
              <Stack spacing={4} growChildren={isMobileScreen ? 'all' : undefined}>
                {macros.map((macro) =>
                  macro.value ? (
                    <Macro key={macro.label} padding={{ y: 8 }} centerContent>
                      <Stack direction="vertical" spacing={4}>
                        <Text style={{ color: main.colors.text_heading }}>
                          {match(macro)
                            .with({ label: 'kcal' }, (macro) => macro.value)
                            .with({ label: 'salt' }, (macro) => `${macro.value || ''}mg`)
                            .otherwise(() => `${macro.value || ''}g`)}
                        </Text>
                        <TextExtraExtraSmall>{macro.label}</TextExtraExtraSmall>
                      </Stack>
                    </Macro>
                  ) : null,
                )}
              </Stack>
            </>
          ))
          .with(false, () => (
            <Box>
              <SectionTitle color={main.colors.text_heading}>
                There is no nutritional information yet
              </SectionTitle>
              <TextExtraSmall style={{ lineHeight: '24px' }}>
                We&apos;re waiting on information to be provided by {vendor.companyName}
              </TextExtraSmall>
            </Box>
          ))
          .exhaustive()}
      </Stack>
    </Modal.Section>
  )
}
