import { FragmentType, graphql, useFragment } from '@/generated/gql'
import { AccountLocation, Address } from '@/generated/gql/graphql'

const basketUiCompatibleGmOrderOrderDataFragment = graphql(`
  fragment BasketUiCompatibleGmOrder_OrderDataFragment on GmOrder {
    accountLocation {
      id
      isGmActive
      instructions
      address {
        floorOrBuildingName
        flatOrBuilding
        street
        postcode
        city
        country
        latitude
        longitude
      }
    }
    deliveryDate
    deliveryTimeSlot
    headCount
  }
`)

type BasketDesignedUiCompatibleOrderData = {
  time: string
  headCount: number
  date: number
  location: {
    __typename: NonNullable<AccountLocation['__typename']>
    address: { __typename: NonNullable<Address['__typename']> } & Pick<
      Address,
      | 'city'
      | 'country'
      | 'flatOrBuilding'
      | 'floorOrBuildingName'
      | 'latitude'
      | 'longitude'
      | 'postcode'
      | 'street'
    >
  } & Pick<AccountLocation, '__typename' | 'address' | 'id'>
}

type Props = {
  orderData: FragmentType<typeof basketUiCompatibleGmOrderOrderDataFragment> | null
}

export const useBasketDesignedUiCompatibleGmOrderData = ({ orderData: orderDataProp }: Props) => {
  const orderData = useFragment(basketUiCompatibleGmOrderOrderDataFragment, orderDataProp)
  const mappedOrderData: BasketDesignedUiCompatibleOrderData | null = orderData
    ? {
        time: orderData.deliveryTimeSlot,
        headCount: Number(orderData.headCount),
        date: new Date(orderData.deliveryDate).getTime(),
        location: {
          ...orderData.accountLocation,
          __typename: 'AccountLocation',
          id: orderData.accountLocation.id,
          address: {
            ...orderData.accountLocation.address,
            city: orderData.accountLocation.address.city ?? '',
            country: orderData.accountLocation.address.country ?? '',
            flatOrBuilding: orderData.accountLocation.address.flatOrBuilding ?? '',
            floorOrBuildingName: orderData.accountLocation.address.floorOrBuildingName ?? '',
            latitude: orderData.accountLocation.address.latitude ?? 0,
            longitude: orderData.accountLocation.address.longitude ?? 0,
            postcode: orderData.accountLocation.address.postcode ?? '',
            street: orderData.accountLocation.address.street ?? '',
            __typename: 'Address',
          },
        },
      }
    : null

  return { orderData: mappedOrderData }
}
