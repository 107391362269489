import React from 'react'

function Icon() {
  return (
    <svg viewBox="5 6 13 12" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9093 8.33398C10.0912 8.33398 7.90934 9.42489 7.90934 11.6067C7.90934 13.1804 9.03726 13.8016 9.67 14.0293C8.4105 15.1459 7.27582 15.4355 7.27582 15.4355C7.08087 15.4842 6.96227 15.6817 7.01091 15.8766C7.05955 16.0715 7.25701 16.1902 7.45196 16.1415C7.45196 16.1415 8.79312 15.8004 10.1991 14.54C10.1995 14.5411 10.1994 14.5416 10.1998 14.5428C10.4318 14.3581 10.6497 14.1707 10.8341 13.986L13.8341 10.986C13.8341 10.986 13.9839 11.8638 13.2566 12.5911L11.3476 14.5002C11.109 14.7387 10.8262 14.9787 10.5258 15.2118C10.8865 15.7675 11.5246 16.334 12.6366 16.334C14.8184 16.334 15.9093 14.7583 15.9093 11.9703V8.33398H11.9093Z" />
    </svg>
  )
}

export default Icon
