import React from 'react'
import { HeadCountInput } from './styles'
import { InputAdornment, TextField, useTheme } from '@mui/material'
import { SPLITS, useFeatureFlag } from '@/helpers/useFeatureFlag'
import PersonIcon from '@mui/icons-material/Person'

type Props = {
  maxHeadCount?: number
  error?: string
  headCount?: number
  unusable?: boolean
  disabled?: boolean
  onChange: (newHeadcount: number) => void
}

const HeadCount: React.FC<Props> = ({
  maxHeadCount = Infinity,
  error,
  headCount,
  unusable,
  disabled,
  onChange,
}) => {
  const useMui = useFeatureFlag(SPLITS.GM_CONFIRM_DETAILS_MUI)
  const theme = useTheme()

  const minHeadCount = 0

  const handleChange = ({ value }: { value: string }) => {
    try {
      const number = Number(value)

      if (number < minHeadCount) {
        onChange(minHeadCount)
        return
      }

      if (number > maxHeadCount) {
        onChange(maxHeadCount)
        return
      }
      onChange(Number(value))
    } catch (e) {
      onChange(0)
    }
  }

  const showError = Boolean(error)

  return (
    <>
      {useMui ? (
        <TextField
          size="small"
          fullWidth
          type="number"
          disabled={disabled}
          placeholder="0"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon color="primary" />
              </InputAdornment>
            ),
          }}
          error={showError}
          helperText={error}
          value={headCount === 0 ? '' : headCount}
          onChange={(e) => handleChange(e.target)}
        />
      ) : (
        <HeadCountInput
          type="number"
          icon="person"
          defaultValue={headCount}
          fill={theme.palette.primary.main}
          unusable={unusable}
          disabled={disabled}
          min={minHeadCount}
          max={maxHeadCount}
          name="headcount"
          placeholder="0"
          onChange={(e) => {
            handleChange(e)
          }}
          onBlur={handleChange}
          errorText={error}
          showError={showError}
        />
      )}
    </>
  )
}

export default HeadCount
