import { IconType } from '@teamfeedr/ui--icon'
import { Allergen, Dietary } from '@/generated/graphql'
import { MenuItem } from './menu-item'

export type MenuItemFilterSection = 'goals' | 'dietaries' | 'allergens'
export type MenuItemFilter = (
  | { type: 'dietary'; value: Dietary }
  | { type: 'allergen'; value: Allergen }
) & {
  filterInputValue: string
  filterArray: string
  label: string
  description?: string
  color: `#${string}`
  icon?: IconType
  predicate: (item: MenuItem) => boolean
  count: number
}
export type MenuItemFilterSections = Record<MenuItemFilterSection, MenuItemFilter[]>

const GOAL_FILTERS = [
  {
    value: Dietary.Vegetarian,
    filterArray: 'dietaries',
    filterInputValue: 'vegetarian',
    label: 'Vegetarian',
    description: 'Meat free meals',
    color: '#A2C74B',
    icon: 'sprout',
  },
  {
    value: Dietary.Vegan,
    filterArray: 'dietaries',
    filterInputValue: 'vegan',
    label: 'Vegan',
    description: 'Plant-based',
    color: '#85A33D',
    icon: 'plant',
  },
  {
    value: Dietary.PlantPacked,
    filterArray: 'dietaries',
    filterInputValue: 'powerplant',
    label: 'Plant packed',
    description: '2+ servings of fruit & veg',
    color: '#58C98F',
    icon: 'plantPacked',
  },
  {
    value: Dietary.HighProtein,
    filterArray: 'dietaries',
    filterInputValue: 'highprotein',
    label: 'High protein',
    description: 'More than 20% protein',
    color: '#595A84',
    icon: 'highProtein',
  },
  {
    value: Dietary.LowCarb,
    filterArray: 'dietaries',
    filterInputValue: 'lowcarb',
    label: 'Low carb',
    description: 'Under 25% carbs',
    color: '#F8A448',
    icon: 'lowCarb',
  },
] as const

const DIETARY_FILTERS = [
  {
    value: Dietary.NoGluten,
    filterInputValue: 'cereals',
    filterArray: 'allergens',
    label: 'No added gluten',
    color: '#F6BD60',
    icon: 'noGluten',
    negate: false,
  },
  {
    value: Dietary.ContainsNuts,
    filterArray: 'allergens',
    filterInputValue: 'nuts',
    label: 'No added nuts',
    color: '#F25F5C',
    icon: 'noNuts',
    negate: true,
  },
  {
    value: Dietary.NoDairy,
    filterArray: 'allergens',
    filterInputValue: 'milk',
    label: 'No added dairy',
    color: '#3AAED8',
    icon: 'noMilk',
    negate: false,
  },
  {
    value: Dietary.NoRefinedSugar,
    filterArray: 'dietaries',
    filterInputValue: 'refined_sugar',
    label: 'No refined sugars',
    color: '#A369F5',
    icon: 'noSugar',
    negate: false,
  },
  {
    value: Dietary.Halal,
    filterArray: 'dietaries',
    filterInputValue: 'halal',
    label: 'Halal',
    color: '#6C6EA0',
    icon: 'halal',
    negate: false,
  },
] as const

const ALLERGEN_FILTERS = [
  {
    value: Allergen.Crustaceans,
    label: 'No crustaceans',
    filterInputValue: 'crustaceans',
    filterArray: 'allergens',
  },
  { value: Allergen.Fish, label: 'No fish', filterInputValue: 'fish', filterArray: 'allergens' },
  { value: Allergen.Eggs, label: 'No eggs', filterInputValue: 'eggs', filterArray: 'allergens' },
  {
    value: Allergen.Peanuts,
    label: 'No peanuts',
    filterInputValue: 'peanuts',
    filterArray: 'allergens',
  },
  {
    value: Allergen.Sesame,
    label: 'No sesame',
    filterInputValue: 'sesame',
    filterArray: 'allergens',
  },
  {
    value: Allergen.Soybeans,
    label: 'No soybeans',
    filterInputValue: 'soybeans',
    filterArray: 'allergens',
  },
  {
    value: Allergen.Celery,
    label: 'No celery',
    filterInputValue: 'celery',
    filterArray: 'allergens',
  },
  {
    value: Allergen.Mustard,
    label: 'No mustard',
    filterInputValue: 'mustard',
    filterArray: 'allergens',
  },
  {
    value: Allergen.Sulphur,
    label: 'No sulphites',
    filterInputValue: 'sulphur',
    filterArray: 'allergens',
  },
  {
    value: Allergen.Molluscs,
    label: 'No molluscs',
    filterInputValue: 'molluscs',
    filterArray: 'allergens',
  },
  { value: Allergen.Lupin, label: 'No lupin', filterInputValue: 'lupin', filterArray: 'allergens' },
] as const

export const createMenuItemFilters = (
  dietariesAndAllergensWithCounts: Record<string, number>,
): MenuItemFilterSections => ({
  goals: GOAL_FILTERS.map((filter) => {
    const predicate = (i: MenuItem) => i.dietaries.includes(filter.value)
    return {
      ...filter,
      type: 'dietary',
      predicate,
      count: dietariesAndAllergensWithCounts[filter.filterInputValue],
    }
  }),
  dietaries: DIETARY_FILTERS.map(({ negate, ...filter }) => {
    const predicate = (i: MenuItem) =>
      negate ? !i.dietaries.includes(filter.value) : i.dietaries.includes(filter.value)
    return {
      ...filter,
      type: 'dietary',
      predicate,
      count: dietariesAndAllergensWithCounts[filter.filterInputValue],
    }
  }),
  allergens: ALLERGEN_FILTERS.map((filter) => {
    const predicate = (i: MenuItem) => !i.allergens.includes(filter.value)
    return {
      ...filter,
      type: 'allergen',
      color: '#96A3A5',
      predicate,
      count: dietariesAndAllergensWithCounts[filter.filterInputValue],
    }
  }),
})
