export const fontSizes = {
  xxs: '12px',
  xs: '14px',
  s: '16px',
  base: '18px',
  m: '20px',
  l: '22px',
  xl: '26px',
  xxl: '30px',
  xxxl: '38px',
  xxxxl: '56px',
}
export const fontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  semi: 600,
  bold: 700,
}
export const dateFormats = {
  text: 'MMM Do YYYY',
  textWithTime: 'MMM Do HH:mm',
  textShort: 'MMM Do',
  numeric: 'YYYY-MM-DD',
}
export const colors = {
  avocado_dark: '#4D9E6A',
  avocado_light: '#E3EBE6',
  avocado: '#61C685',
  avocado_60: '#A0DDB6',
  avocado_40: '#C0E8CE',
  avocado_10: '#EFF9F3',
  melon_dark: '#CC4F57',
  melon: '#FF636D',
  melon_60: '#FFA1A7',
  melon_40: '#FFC0C4',
  melon_10: '#FFEFF0',
  red: '#FC495A',
  banana_dark: '#CCA32C',
  banana: '#FFCC37',
  banana_60: '#FFE087',
  banana_40: '#FFE087',
  banana_10: '#FFFAEB',
  berry_dark: '#781C38',
  berry: '#962446',
  berry_60: '#C07B90',
  berry_40: '#D5A7B5',
  berry_10: '#F4E9EC',
  coal: '#3F4144',
  pepper: '#6B8083',
  pepper_60: '#96A3A5',
  pepper_40: '#C2C7C8',
  pepper_30: '#dedede',
  pepper_5: '#F7F9F9',
  milk: '#F2F2F2',
  salt: '#FAFAFA',
  grey_5: '#ededed',
  grey_15: '#a6b3b5',
  grey_30: '#333333',
  grey_text: '#4a4a4a',
  grey_light: '#a6b3b5',
  grey_lightest: '#DFDFDF',
  white: '#ffffff',
  black: '#000000',
}
export const legacyColors = {
  greyCopy: '#757575',
  greyCopySecondary: '#9B9B9B',
  greyBlack: '#363636',
  greyWhite: '#F5F5F5',
  invalid: '#E11F26',
  feedrPink: '#FF6963',
  red: '#F05B3C',
  amber: '#F5A623',
}
