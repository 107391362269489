import { useEffect, useState } from 'react'

type Props = {
  orderId?: string
}

export const useOrderDrawerState = ({ orderId: orderIdProp }: Props) => {
  const [currentOrderInDrawer, setCurrentOrderInDrawer] = useState('')
  const [showOrderDrawer, setShowOrderDrawer] = useState(false)

  useEffect(() => {
    if (!orderIdProp || orderIdProp === currentOrderInDrawer) return
    setShowOrderDrawer(true)
    setCurrentOrderInDrawer(orderIdProp)
  }, [orderIdProp, currentOrderInDrawer])

  const toggleOrderDrawer = (open: boolean) => {
    setShowOrderDrawer(open)
  }

  return {
    showOrderDrawer,
    toggleOrderDrawer,
  }
}
