import { useGetBasketLazyQuery } from '@/generated/graphql'

export const useGetBasketQuery = () => {
  const [run, result] = useGetBasketLazyQuery()

  const error =
    result.error ||
    (result.called && !result.loading && !result.data?.basket ? 'Unknown error' : null)

  const getBasket = (basketId: string) => {
    void run({ variables: { id: basketId } })
  }

  return {
    run: getBasket,
    loading: result.loading,
    basket: result.data?.basket,
    error,
  }
}
