import { PaymentMethod } from '@/generated/gql/graphql'
import { numberFormat } from '@/helpers/numbers'
import { isBefore } from 'date-fns'
import { useEffect, useState } from 'react'
import { useInterval } from 'react-use'

type Props = {
  changesMade: boolean
  minimumOrderValue: number
  userEditCutOffTime: Date | null
  subtotalExTax: number
  paymentMethod?: PaymentMethod
}

type Errors = Partial<{
  PAST_EDIT_CUT_OFF: { cutOffTime: Date }
  MINIMUM_SPEND_NOT_MET: { minimumOrderValue: string; amountRequired: string }
  INVALID_PAYMENT_METHOD: { paymentMethod: PaymentMethod }
}>

export const useExistingOrderValidation = ({
  changesMade,
  minimumOrderValue,
  subtotalExTax,
  userEditCutOffTime,
  paymentMethod,
}: Props) => {
  const [errors, setErrors] = useState<Errors>({})

  useInterval(() => {
    if (!userEditCutOffTime) return
    const isPastEditCutOff = isBefore(userEditCutOffTime, new Date())
    setErrors((prev) => {
      const newErrors = { ...prev }
      if (!isPastEditCutOff) delete newErrors.PAST_EDIT_CUT_OFF
      else newErrors.PAST_EDIT_CUT_OFF = { cutOffTime: userEditCutOffTime }
      return newErrors
    })
  }, 3000)

  useEffect(() => {
    if (subtotalExTax < minimumOrderValue) {
      const remainingAmountRequired = minimumOrderValue - subtotalExTax
      setErrors((prev) => ({
        ...prev,
        MINIMUM_SPEND_NOT_MET: {
          minimumOrderValue: numberFormat(minimumOrderValue / 100),
          amountRequired: numberFormat(remainingAmountRequired / 100),
        },
      }))
    } else
      setErrors((prev) => {
        const newState = { ...prev, MINIMUM_SPEND_NOT_MET: undefined }
        delete prev.MINIMUM_SPEND_NOT_MET
        return newState
      })
  }, [subtotalExTax, minimumOrderValue])

  useEffect(() => {
    if (!paymentMethod) return
    if (paymentMethod === PaymentMethod.Card)
      setErrors((prev) => ({
        ...prev,
        INVALID_PAYMENT_METHOD: { paymentMethod },
      }))
  }, [paymentMethod])

  const canSaveChanges =
    changesMade &&
    (Object.keys(errors).length === 0 || Object.values(errors).some((error) => !error))

  return {
    errors,
    canSaveChanges,
  }
}
