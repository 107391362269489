import React from 'react'

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        style={{
          lineHeight: 'normal',
          textIndent: '0',
          textAlign: 'start',
          WebkitTextDecorationLine: 'none',
          textDecorationLine: 'none',
          WebkitTextDecorationStyle: 'solid',
          textDecorationStyle: 'solid',
          WebkitTextDecorationColor: '#000',
          textDecorationColor: '#000',
          textTransform: 'none',
          isolation: 'auto',
          mixBlendMode: 'normal',
        }}
        d="M9 3a1 1 0 00-1 1v1.055L5.16 9.457A1 1 0 005 10v16a1 1 0 001 1h16a1 1 0 001-1V10a1 1 0 00-.16-.543L20 5.057V4a1 1 0 00-1-1H9zm.771 3h8.223L16 9H7.836L9.77 6zM7 11h9v14H7V11z"
        fontFamily="sans-serif"
        fontWeight="400"
        overflow="visible"
      />
    </svg>
  )
}

export default Icon
