import React from 'react'

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path d="M15.668 3a3.407 3.407 0 00-3.256 2.412A3.407 3.407 0 0010 8.668c0 1.025.465 1.971 1.227 2.603a3.403 3.403 0 003.044 4.186c.178.019.274.12.272.272a3.405 3.405 0 004.186 3.044A3.388 3.388 0 0021.332 20a3.407 3.407 0 003.256-2.412A3.407 3.407 0 0027 14.332a3.36 3.36 0 00-.463-1.7A3.36 3.36 0 0027 10.935a3.387 3.387 0 00-1.227-2.606 3.403 3.403 0 00-4.101-4.101A3.391 3.391 0 0019.066 3a3.36 3.36 0 00-1.699.463A3.363 3.363 0 0015.668 3zM9.225 13.014c-2.273 2.048-4.148 3.748-4.537 4.138a5.779 5.779 0 000 8.162A5.755 5.755 0 008.768 27a5.754 5.754 0 004.08-1.686c.387-.387 2.093-2.259 4.154-4.535a5.392 5.392 0 01-2.334-1.033l-2.961 2.961a.997.997 0 01-1.414 0 .999.999 0 010-1.414l3.039-3.04a5.352 5.352 0 01-.518-1.067 5.352 5.352 0 01-1.068-.518l-3.039 3.039a.997.997 0 01-1.414 0 .999.999 0 010-1.414l2.96-2.961a5.372 5.372 0 01-1.028-2.318z" />
    </svg>
  )
}

export default Icon
