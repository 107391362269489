import React, { useCallback } from 'react'
import {
  calculateOptions,
  timePopoverFormat,
  getMinDateAllowed,
} from '@teamfeedr/utils--gm-validation'
import LegacySelect from '@teamfeedr/ui--select'
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  MenuItem,
  Select,
  useTheme,
} from '@mui/material'
import { MenuVendorLocationWithDeliveryRegionsFragment } from '@/generated/graphql'
import { SPLITS, useFeatureFlag } from '@/helpers/useFeatureFlag'
import AccessTimeIcon from '@mui/icons-material/AccessTime'

type Props = {
  availableVendorLocations: MenuVendorLocationWithDeliveryRegionsFragment[]
  selectedDate?: number
  selectedTime?: string
  error?: string
  onTimeChange: (time: string) => void
}

const TimeSelect: React.FC<Props> = ({
  selectedDate,
  selectedTime,
  availableVendorLocations,
  error,
  onTimeChange,
}) => {
  const theme = useTheme()
  const cutOff5pm = useFeatureFlag(SPLITS.GM_CUTOFF_5PM)
  const useMui = useFeatureFlag(SPLITS.GM_CONFIRM_DETAILS_MUI)

  const getTimeOptions = useCallback(() => {
    return calculateOptions(availableVendorLocations, selectedDate, cutOff5pm)
  }, [availableVendorLocations, selectedDate, cutOff5pm])

  const minDate = getMinDateAllowed(
    availableVendorLocations,
    {
      date: selectedDate,
      time: selectedTime,
    },
    cutOff5pm,
  )

  const time = `${minDate.getHours()}${minDate.getMinutes().toString().padStart(2, '0')}`
  const chosenTime =
    minDate && selectedDate && minDate >= new Date(selectedDate) ? parseInt(time, 10) : undefined
  const options = timePopoverFormat({
    ...getTimeOptions(),
    chosenTime,
  }).map((value) => ({ value, label: value }))

  const handleTimeChange = (option: { value: string } | null) => {
    if (!option) {
      return
    }
    onTimeChange(option.value)
  }

  return (
    <>
      {useMui ? (
        <FormControl fullWidth error={Boolean(error)}>
          <Select
            size="small"
            disabled={!selectedDate}
            displayEmpty
            fullWidth
            onChange={(e) => handleTimeChange(e.target.value ? { value: e.target.value } : null)}
            startAdornment={
              <InputAdornment position="start">
                <AccessTimeIcon color="primary" />
              </InputAdornment>
            }
            renderValue={(selected) => {
              if (!selected) {
                return <span>Time</span>
              }

              return selected
            }}
            value={selectedTime || ''}
          >
            <MenuItem value="" disabled>
              <em>Time</em>
            </MenuItem>
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{error}</FormHelperText>
        </FormControl>
      ) : (
        <LegacySelect
          placeholder="Time"
          icon="timer"
          fill={theme.palette.primary.main}
          value={selectedTime ? { value: selectedTime, label: selectedTime } : null}
          disabled={!selectedDate}
          errorText={error}
          showError={Boolean(error)}
          noErrors={Boolean(!error)}
          onChange={handleTimeChange}
          options={options}
          isSearchable={false}
        />
      )}
    </>
  )
}

export default TimeSelect
