import React from 'react'

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path d="M15 3c-1.159 0-2.128.557-2.664 1.62-.292.578-1.053 1.22-1.578 1.411C10.233 6.222 8.982 6 8.5 6 7.119 6 6 7.17 6 8.55c0 .494.31 2.014-.143 2.602-.452.587-1.287.883-1.484.948A2.002 2.002 0 003 14c0 .446.151.854.398 1.188C4.3 16.698 6.163 18.602 7 19.3c0 0 1.866.265 4.023.469A3.988 3.988 0 0115 16c2.13 0 3.856 1.67 3.977 3.77C21.134 19.566 23 19.3 23 19.3c.837-.697 2.7-2.602 3.602-4.113.247-.332.398-.741.398-1.187 0-.886-.576-1.637-1.373-1.9-.197-.065-1.032-.36-1.484-.948C23.69 10.565 24 9.044 24 8.551 24 7.17 22.881 6 21.5 6c-.482 0-1.733.222-2.258.031-.525-.19-1.286-.833-1.578-1.412C17.128 3.557 16.16 3 15 3zM5.002 21a1 1 0 00-.895 1.447C4.89 24.012 10 26 15 26c5 0 10.11-1.988 10.893-3.553A1 1 0 0024.998 21S20 22 15 22c-5 0-9.998-1-9.998-1z" />
    </svg>
  )
}

export default Icon
