import { Avatar, Stack, SvgIcon, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { match } from 'ts-pattern'
import Icon from './Icon'

export const Dietaries = [
  'vegan',
  'vegetarian',
  'halal',
  'kosher',
  'noRefinedSugar',
  'containsNuts',
  'noDairy',
  'noGluten',
  'pescatarian',
] as const

export const AllergensAndFoodGoals = [
  'cereals',
  'peanuts',
  'fish',
  'eggs',
  'nuts',
  'molluscs',
  'crustaceans',
  'soybeans',
  'milk',
  'celery',
  'mustard',
  'sesame',
  'sulphur',
  'lupin',
  'lowCarb',
  'highProtein',
  'plantPacked',
  'lowCarbon',
  'veryLowCarbon',
  'none',
] as const

export const SubAllergensCereals = ['barley', 'oats', 'rye', 'wheat', 'kamut'] as const

export const SubAllergensNuts = [
  'almonds',
  'brazil',
  'cashew',
  'hazelnuts',
  'macadamia',
  'pecan',
  'pistachio',
  'walnuts',
] as const

const getColors = (type: DietariesType | AllergensAndFoodGoalsType) =>
  match(type)
    .with('vegan', () => ({
      color: '#A2C74B',
      backgroundColor: 'rgb(235 240 217)',
    }))
    .with('noDairy', () => ({ color: '#3AAED8', backgroundColor: '#EBF7FB' }))
    .with('vegetarian', () => ({
      color: '#4D9E6A',
      backgroundColor: '#EFF9F3',
    }))
    .with('pescatarian', () => ({
      color: '#A369F5',
      backgroundColor: '#F6F0FE',
    }))
    .with('noGluten', () => ({ color: '#F6BD60', backgroundColor: '#FEF8EF' }))
    .with('noRefinedSugar', () => ({
      color: '#2D384C',
      backgroundColor: '#D2E5E9',
    }))
    .with('containsNuts', () => ({
      color: '#F25F5C',
      backgroundColor: '#FDEFEE',
    }))
    .with('halal', () => ({ color: '#6C6EA0', backgroundColor: '#F0F0F5' }))
    .with('kosher', () => ({ color: '#D84D88', backgroundColor: '#FEE9F2' }))
    .with('lowCarb', () => ({ color: '#F8A448', backgroundColor: '#F8A24630' }))
    .with('lowCarbon', () => ({ color: '#FFFFFF', backgroundColor: '#FFCC37' }))
    .with('veryLowCarbon', () => ({
      color: '#FFFFFF',
      backgroundColor: '#61C685',
    }))
    .with('highProtein', () => ({
      color: '#595A84',
      backgroundColor: '#59598430',
    }))
    .with('plantPacked', () => ({
      color: '#58C98F',
      backgroundColor: '#56CA8E30',
    }))
    .with('none', () => ({
      color: 'rgb(194, 199, 200)',
      backgroundColor: 'unset',
    }))
    .otherwise(() => ({
      color: '#FF636D',
      backgroundColor: 'rgb(255, 239, 240)',
    }))

export type DietariesType = (typeof Dietaries)[number]
export type AllergensAndFoodGoalsType = (typeof AllergensAndFoodGoals)[number]
export type SubAllergensCerealsType = (typeof SubAllergensCereals)[number]
export type SubAllergensNutsType = (typeof SubAllergensNuts)[number]

export interface Props {
  type: DietariesType | AllergensAndFoodGoalsType
  size?: number
  subAllergens?: {
    cereals?: SubAllergensCerealsType[]
    nuts?: SubAllergensNutsType[]
  }
  displayLabel?: boolean
}

const DietaryAbbreviations = {
  vegan: 'VE',
  vegetarian: 'V',
  halal: 'H',
  kosher: 'K',
  containsNuts: 'N!',
  noDairy: 'ND',
  noGluten: 'NG',
  noRefinedSugar: 'NRS',
  pescatarian: 'P',
}

export const dietaryLabels: {
  [key in
    | DietariesType
    | AllergensAndFoodGoalsType
    | SubAllergensCerealsType
    | SubAllergensNutsType]: string
} = {
  vegan: 'Vegan',
  vegetarian: 'Vegetarian',
  halal: 'Halal',
  kosher: 'Kosher',
  noRefinedSugar: 'No added refined sugar',
  containsNuts: 'Contains nuts',
  noDairy: 'No added dairy',
  noGluten: 'No added gluten',
  lowCarbon: 'Low carbon footprint',
  veryLowCarbon: 'Very low carbon footprint',
  pescatarian: 'Pescatarian',
  cereals: 'Cereals containing gluten',
  peanuts: 'Peanuts',
  fish: 'Fish',
  eggs: 'Eggs',
  nuts: 'Tree nuts',
  molluscs: 'Molluscs',
  crustaceans: 'Crustaceans',
  soybeans: 'Soybeans',
  milk: 'Milk',
  celery: 'Celery',
  mustard: 'Mustard',
  sesame: 'Sesame',
  sulphur: 'Sulphites',
  lupin: 'Lupin',
  lowCarb: 'Low carb',
  highProtein: 'High protein',
  plantPacked: 'Plant packed',
  none: 'No known allergens',
  barley: 'barley',
  oats: 'oats',
  rye: 'rye',
  wheat: 'wheat',
  kamut: 'kamut',
  almonds: 'almonds',
  brazil: 'brazil nuts',
  cashew: 'cashew nuts',
  hazelnuts: 'hazelnuts',
  macadamia: 'macadamia nuts',
  pecan: 'pecan nuts',
  pistachio: 'pistachio nuts',
  walnuts: 'walnuts',
}

const getLabel = ({ type, subAllergens }: Pick<Props, 'type' | 'subAllergens'>) => {
  if (subAllergens && (type === 'nuts' || type === 'cereals')) {
    const subAllergenArray = subAllergens[type] || []
    const subAllergenList = subAllergenArray.map((subAllergen) => dietaryLabels[subAllergen])

    return subAllergenList.length > 0
      ? `${dietaryLabels[type]} (${subAllergenList.join(', ')})`
      : dietaryLabels[type]
  }
  return dietaryLabels[type]
}

const DietaryBadge: React.FC<Props> = ({ type, size = 26, subAllergens, displayLabel }) => {
  const [show, setShow] = React.useState(false)
  const handleClick = () => {
    if (show) {
      setShow(false)
    } else {
      setShow(true)
    }
  }
  const isAllergen = (allergenType: string): allergenType is AllergensAndFoodGoalsType =>
    AllergensAndFoodGoals.includes(allergenType as AllergensAndFoodGoalsType)

  const label = getLabel({ type, subAllergens })

  const { backgroundColor, color } = getColors(type)

  return (
    <Stack spacing={1} direction="row">
      <div
        onFocus={() => {}}
        style={{ display: 'inline' }}
        onMouseOver={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        <Tooltip open={show} onClick={handleClick} title={label}>
          <Avatar sx={{ bgcolor: backgroundColor, width: size, height: size }}>
            {isAllergen(type) ? (
              <SvgIcon htmlColor={color} fontSize="small">
                <Icon type={type} />
              </SvgIcon>
            ) : (
              <Typography color={color} variant="caption">
                {DietaryAbbreviations[type]}
              </Typography>
            )}
          </Avatar>
        </Tooltip>
      </div>
      {displayLabel ? <Typography variant="body1">{label}</Typography> : null}
    </Stack>
  )
}

export default DietaryBadge
