import React, { useState } from 'react'
import { CustomerFacingExistingGmOrderItem } from '../states/useExistingOrder/useExistingOrderItems'
import { Button, Card, Chip, Collapse, IconButton, Stack, Typography } from '@mui/material'
import { Close, ExpandLess, ExpandMore, Notes } from '@mui/icons-material'
import QuantitySelect from '@/components/QuantitySelect'
import { Currency, getCurrencySymbol } from '@teamfeedr/utils--money'

type Props = {
  item: CustomerFacingExistingGmOrderItem
  currency: string
  taxAcronym: string
  onAdjustItemQuantity: (input: { id: string; newQty: number }) => void
  onRemoveItem: (input: { id: string }) => void
}

const ItemCard: React.FC<Props> = ({
  item,
  currency,
  taxAcronym,
  onAdjustItemQuantity,
  onRemoveItem,
}) => {
  const [expanded, setExpanded] = useState(false)

  const itemKey = item.id

  return (
    <Card key={itemKey}>
      <Stack paddingX={2} paddingY={1} spacing={0.5}>
        <Stack alignItems="center" justifyContent="space-between" width="100%" direction="row">
          <Typography variant="body1">{item.name}</Typography>
          {!item.isCustom ? (
            <IconButton
              sx={{ width: 'fit-content' }}
              disabled={item.isCustom}
              onClick={() =>
                onRemoveItem({
                  id: item.id,
                })
              }
            >
              <Close />
            </IconButton>
          ) : null}
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <QuantitySelect
            min={1}
            quantity={item.qty}
            onChange={(newValue) =>
              onAdjustItemQuantity({
                newQty: newValue,
                id: item.id,
              })
            }
            disabled={!item.quantityAdjustable}
          />
          <Typography variant="body2">
            {getCurrencySymbol(currency as Currency)}
            {item.priceExTax} (ex {taxAcronym})
          </Typography>
        </Stack>
        {item.options.length > 0 ? (
          <Stack direction="column">
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Chip color="primary" label="Options" size="small" />
              <Button
                onClick={() => setExpanded((prev) => !prev)}
                endIcon={expanded ? <ExpandLess /> : <ExpandMore color="primary" />}
                size="small"
                variant="text"
              >
                {expanded ? 'Hide' : 'Show'}
              </Button>
            </Stack>
            <Collapse in={expanded}>
              <Stack paddingY={1} spacing={0.5}>
                {item.options.map((option) => (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    key={`${itemKey}-${option.id}`}
                  >
                    <Typography color="text.secondary" variant="body2">
                      {option.qty} x {option.name}
                    </Typography>
                    <Typography color="text.secondary" variant="body2">
                      {getCurrencySymbol(currency as Currency)}
                      {option.priceExTax} (ex {taxAcronym})
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Collapse>
          </Stack>
        ) : null}
        {item.note && (
          <Stack spacing={1} direction="row">
            <Notes fontSize="small" />
            <Typography variant="caption">Notes: {item.note}</Typography>
          </Stack>
        )}
      </Stack>
    </Card>
  )
}

export default ItemCard
