import React from 'react'

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path d="M15 0a.5.5 0 00-.492.418L13.91 4H12a1 1 0 00-1 1v2h8V5a1 1 0 00-1-1h-1.91L15.492.418A.5.5 0 0015 0zm-3 9a3 3 0 00-3 3v2h1a2 2 0 012 2v6a2 2 0 01-2 2H9v2a2 2 0 002 2h8a2 2 0 002-2V12a3 3 0 00-3-3h-6z" />
    </svg>
  )
}

export default Icon
