import React from 'react'

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path d="M20.077 0C11.154 0 2.23 4.497 2.23 15.738c0 2.575.375 4.576 1.055 6.147-.302.715-.709 1.77-1.22 3.163C.695 28.778.006 32.42 0 35.973V36l2.23-.027h2.232v-.027c-.036-3.345 1.184-8.93 3.948-14.5a44.974 44.974 0 012.3-4.073c1.577-2.258 3.241-4.542 4.783-6.095a2.216 2.216 0 013.154-.018c.872.876.881 2.3.009 3.179-3.685 3.74-6.273 7.86-7.86 12.401.82.095 1.683.14 2.589.14C20.077 26.98 29 20.235 29 6.745V0h-8.923z" />
    </svg>
  )
}

export default Icon
