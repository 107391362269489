import React from 'react'

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 29">
      <path d="M14.5 0C6.496 0 0 6.496 0 14.5S6.496 29 14.5 29 29 22.504 29 14.5 22.504 0 14.5 0zM2.9 14.5c0-6.409 5.191-11.6 11.6-11.6 2.683 0 5.148.913 7.105 2.45L5.35 21.605A11.458 11.458 0 012.9 14.5zm11.6 11.6c-2.682 0-5.148-.914-7.105-2.45L23.65 7.395A11.458 11.458 0 0126.1 14.5c0 6.409-5.191 11.6-11.6 11.6z" />
    </svg>
  )
}

export default Icon
