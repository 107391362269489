import { MenuCategory } from './menu-category'

export type SelectedMenuFilterCategory = {
  name: string
  id: string
  predicate: (category: MenuCategory) => MenuCategory | null
}

export type MenuFilterCategory = {
  count: number
} & SelectedMenuFilterCategory

export const EVERYTHING_FILTER_CATEGORY: SelectedMenuFilterCategory = {
  id: 'everything',
  name: 'Everything',
  predicate: (c) => c,
}

export const createMenuFilterCategories = (
  menuCategories: MenuCategory[],
  categoriesWithCounts: Record<string, number>,
): MenuFilterCategory[] => [
  {
    ...EVERYTHING_FILTER_CATEGORY,
    count: categoriesWithCounts.everything ?? 0,
  },
  ...menuCategories
    .filter(({ id }) => id !== 'everything')
    .map<MenuFilterCategory>(({ name, id }) => {
      const predicate = (c: MenuCategory) => (c.name === name ? c : null)
      return {
        name,
        id,
        predicate,
        count: categoriesWithCounts[id] ?? 0,
      }
    }),
  {
    name: 'Hot dishes',
    id: 'hot_dishes',
    predicate: (category) => ({
      ...category,
      items: category.items.filter((i) => i.isHot),
    }),
    count: categoriesWithCounts.hot_dishes ?? 0,
  },
  {
    name: 'Cold dishes',
    id: 'cold_dishes',
    predicate: (category) => ({
      ...category,
      items: category.items.filter((i) => !i.isHot),
    }),
    count: categoriesWithCounts.cold_dishes ?? 0,
  },
]
