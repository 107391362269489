import { isBefore, isAfter, isEqual, startOfDay } from 'date-fns'
import { MenuItemFragment, MenuItemVariationFragment } from '@/generated/graphql'
import { areFieldsNotNil, isNotNil, Replace, RequireFields } from '../utils/typing'
import { createMenuItemOption, MenuItemOption } from './menu-item-option'

export type MenuItem = Replace<
  RequireFields<
    Omit<MenuItemFragment, 'variations'> & Omit<MenuItemVariationFragment, '__typename'>,
    'isActive' | 'name' | 'price' | 'servings' | 'minQty' | 'priceExTax' | 'taxRate' | 'taxValue'
  >,
  { options: MenuItemOption[] }
> & { variationId: string }

export const filterActiveItems = (item: MenuItemFragment, deliveryDate?: number) => {
  const date = deliveryDate ? new Date(deliveryDate) : startOfDay(new Date())
  const activeFrom = new Date(item.activeFrom as string)
  const activeTo = new Date(item.activeTo as string)
  if (item.activeFrom && isEqual(date, activeFrom)) return true
  if (item.activeTo && isEqual(date, activeTo)) return true
  if (item.activeFrom && item.activeTo) {
    return isAfter(date, activeFrom) && isBefore(date, activeTo)
  }
  if (item.activeFrom) return isAfter(date, activeFrom)
  if (item.activeTo) return isBefore(date, activeTo)
  return true
}

export const createMenuItems = (items: MenuItemFragment[]): MenuItem[] =>
  items
    .filter(isNotNil)
    .filter(areFieldsNotNil(['name', 'price', 'options', 'variations']))
    .map((item) => {
      const { variations, options, ...restItem } = item
      const variation = variations[0]
      if (!variation) return null

      // TODO: change eslint rule
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { __typename, _id, ...variationWithoutTypename } = variation

      return {
        ...restItem,
        ...variationWithoutTypename,
        variationId: _id,
        isActive: !!restItem.isActive,
        price: variation.priceCustomerFacing,
        priceExTax: variation.priceCustomerFacingExTax,
        taxRate: restItem.taxRate,
        taxValue: variation.taxValue || 0,
        options: options
          .filter(isNotNil)
          .map(createMenuItemOption)
          .filter((o) => o.items.length > 0),
        servings: variation.servings || 1,
        minQty: variation.minQty || 1,
        isPrivate: variation.isPrivate || false,
      }
    })
    .filter(isNotNil)
