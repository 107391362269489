import useTenant from '@/hooks/useTenant'

const useAllergenInfoText = () => {
  const tenant = useTenant()
  if (tenant.id === '66ab5c5872a12355ff1f968a') return ''

  return `Product Information is provided by the Vendor and cannot be guaranteed by ${tenant.name}. This product may contain trace allergens. Check marked allergens before consuming. Contact the vendor for any questions.`
}

export default useAllergenInfoText
