import React from 'react'
import MenuItemSearch from './menu-item-search'
import { MenuFilterCategory, SelectedMenuFilterCategory } from '../../domain/menu-category-filter'
import { MenuItemFilterSections } from '../../domain/menu-item-filter'
import Categories from './categories'
import Filters from './filters'
import { Box, Stack, Typography } from '@mui/material'

type Props = {
  categories: MenuFilterCategory[]
  filterSections: MenuItemFilterSections
  selectedCategory: SelectedMenuFilterCategory
  onCategoryChange: (category: string) => void
  onItemNameChange: (itemName: string) => void
  itemNameSearchValue?: string
  asRow: boolean
}

const SideBarFilters = ({
  categories,
  filterSections,
  selectedCategory,
  onCategoryChange,
  onItemNameChange,
  itemNameSearchValue,
  asRow,
}: Props) => {
  return (
    <Stack spacing={asRow ? 1 : 0} position="sticky" top={5} direction={asRow ? 'row' : 'column'}>
      <Box minWidth={asRow ? 250 : 'unset'}>
        <MenuItemSearch
          onItemNameChange={onItemNameChange}
          itemNameSearchValue={itemNameSearchValue}
        />
      </Box>
      <Stack>
        {asRow ? (
          <Typography marginBottom={1} color="text.secondary">
            Filters
          </Typography>
        ) : null}
        <Stack
          flexWrap={asRow ? 'wrap' : 'nowrap'}
          useFlexGap={asRow}
          spacing={asRow ? 1 : 0}
          top={5}
          direction={asRow ? 'row' : 'column'}
        >
          <Categories
            asRow={asRow}
            categories={categories}
            selectedCategory={selectedCategory}
            onCategoryChange={onCategoryChange}
          />
          <Filters asRow={asRow} filterSections={filterSections} />
        </Stack>
      </Stack>
    </Stack>
  )
}

export default SideBarFilters
