import React, { useState } from 'react'
import styled from 'styled-components'
import { main } from '@teamfeedr/ui--theme'
import { MenuFilterCategory, SelectedMenuFilterCategory } from '../../domain/menu-category-filter'
import {
  Collapse,
  FormControlLabel,
  Menu,
  Radio,
  Stack,
  ToggleButton,
  Typography,
} from '@mui/material'
import { Add, Remove } from '@mui/icons-material'

type Props = {
  categories: MenuFilterCategory[]
  selectedCategory: SelectedMenuFilterCategory
  asRow: boolean
  onCategoryChange: (category: string) => void
}

const RadioButtons = styled.div`
  display: flex;
  flex-direction: column;
  background: ${main.colors.canvas};
  border-radius: 5px;
  padding: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
`

const Categories: React.FC<Props> = ({ categories, selectedCategory, asRow, onCategoryChange }) => {
  const [expanded, setExpanded] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <Stack spacing={1} width={{ xs: '100%', md: asRow ? '250px' : '100%' }}>
      <ToggleButton
        value=""
        color="primary"
        size={asRow ? 'large' : 'medium'}
        sx={{ width: '100%', justifyContent: 'space-between', backgroundColor: 'background.paper' }}
        onClick={(e) => {
          setAnchorEl(e.currentTarget)
          setExpanded((prev) => !prev)
        }}
        selected={!!(selectedCategory.name && selectedCategory.name !== 'Everything')}
      >
        {selectedCategory.name && selectedCategory.name !== 'Everything'
          ? `${selectedCategory.name}`
          : 'Categories'}
        {!expanded || !open ? <Add /> : <Remove />}
      </ToggleButton>
      {asRow ? (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <Stack direction="column" paddingX={2}>
            {categories.map((category, i) => (
              <FormControlLabel
                key={i}
                label={
                  <Stack alignItems="center" direction="row">
                    {category.name}&nbsp;
                    <Typography variant="caption" color="grey.500">
                      ({category.count})
                    </Typography>
                  </Stack>
                }
                control={
                  <Radio
                    checked={selectedCategory.name === category.name}
                    value={`${i}`}
                    onChange={() => onCategoryChange(category.name)}
                    name="categories"
                  />
                }
              />
            ))}
          </Stack>
        </Menu>
      ) : (
        <Collapse in={expanded}>
          <RadioButtons>
            {categories.map((category, i) => (
              <FormControlLabel
                key={i}
                label={`${category.name} (${category.count})`}
                control={
                  <Radio
                    checked={selectedCategory.name === category.name}
                    value={`${i}`}
                    onChange={() => onCategoryChange(category.name)}
                    name="categories"
                  />
                }
              />
            ))}
          </RadioButtons>
        </Collapse>
      )}
    </Stack>
  )
}

export default Categories
