import countries from '@/generated/countries.json'
import { z } from 'zod'
import { CountryCode, Currency, Language } from '@/generated/graphql'

const countrySchema = z.object({
  name: z.string(),
  code: z.nativeEnum(CountryCode),
  currency: z.nativeEnum(Currency),
  flagImageUri: z.object({
    square: z.string(),
    rectangle: z.string(),
  }),
  defaultLanguage: z.string(),
  languages: z.array(z.nativeEnum(Language)),
  locales: z.array(z.string()),
})

export default countries.map((country) => countrySchema.parse(country))

export const getCurrencyCodeForCountry = (countryCode: string) => {
  return countries.find((country) => country.code.toLowerCase() === countryCode)?.currency
}
