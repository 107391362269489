import { FragmentType, graphql, useFragment } from '@/generated/gql'
import { numberFormat } from '@/helpers/numbers'
import { useEffect, useState } from 'react'
import { useCounter } from 'react-use'

const useExistingOrderItemsOrderDataFragment = graphql(`
  fragment UseExistingOrderItems_OrderDataFragment on GmOrder {
    id
    orderItems {
      items {
        isCustom
        id
        name
        note
        priceExTax
        price
        qty
        taxRate
        quantityAdjustable
        liveMenuItem {
          _id
        }
        orderItemOptions {
          qty
          id
          name
          priceExTax
          price
          taxRate
          optionReference
        }
      }
    }
  }
`)

type CustomerFacingExistingGmOrderItemOptionInternal = Omit<
  CustomerFacingExistingGmOrderItemOption,
  'priceExTax' | 'priceIncTax'
> & {
  priceExTax: number
  priceIncTax: number
}

type CustomerFacingExistingGmOrderItemOption = {
  id: string
  optionCategoryId?: string
  name: string
  priceExTax: string
  priceIncTax: string
  qty: number
  taxRate: number
}

export type CustomerFacingExistingGmOrderItemInternal = Omit<
  CustomerFacingExistingGmOrderItem,
  'priceExTax' | 'priceIncTax' | 'options'
> & {
  priceExTax: number
  priceIncTax: number
  options: Array<CustomerFacingExistingGmOrderItemOptionInternal>
}

export type CustomerFacingExistingGmOrderItem = {
  isCustom: boolean
  isExistingOrderItem: boolean
  hasChanges: boolean
  liveItemId: string
  variationId?: string
  id: string
  name: string
  priceIncTax: string
  priceExTax: string
  taxRate: number
  options: Array<CustomerFacingExistingGmOrderItemOption>
  qty: number
  note?: string
  quantityAdjustable: boolean
}

type Props = {
  orderData: FragmentType<typeof useExistingOrderItemsOrderDataFragment> | null
}

type TemporaryIdInput = {
  itemCoreId: string
  options: Array<{ optionId: string; qty: number }>
}

export const useExistingOrderItems = ({ orderData: orderDataProp }: Props) => {
  const orderData = useFragment(useExistingOrderItemsOrderDataFragment, orderDataProp)
  const [orderItemsInternal, setOrderItemsInternal] = useState<
    Array<CustomerFacingExistingGmOrderItemInternal>
  >([])
  const [orderItems, setOrderItems] = useState<Array<CustomerFacingExistingGmOrderItem>>([])
  const [removedOrderItems, setRemovedOrderItems] = useState<
    Array<CustomerFacingExistingGmOrderItemInternal>
  >([])
  const [changesMade, setChangesMade] = useState(false)
  const [newItemCount, newItemCountState] = useCounter(0)

  const itemQuantity = orderItems.reduce((runningTotal, item) => runningTotal + item.qty, 0)

  const createTemporaryId = (input: TemporaryIdInput) => {
    const options = input.options.map((option) => `${option.optionId}:${option.qty}`).join('-')
    return `temp-${input.itemCoreId}-${options}`
  }

  useEffect(() => {
    if (!orderData) return
    const mappedItemsInternal: Array<CustomerFacingExistingGmOrderItemInternal> =
      orderData.orderItems.items.map((item) => ({
        temporaryId: createTemporaryId({
          itemCoreId: item.liveMenuItem?._id ?? `nolive-${item.id}`,
          options: item.orderItemOptions.map((option) => ({
            qty: option.qty,
            optionId: option.optionReference,
          })),
        }),
        id: item.id,
        hasChanges: false,
        isCustom: item.isCustom,
        quantityAdjustable: item.quantityAdjustable,
        isExistingOrderItem: true,
        name: item.name,
        note: item.note ?? undefined,
        priceExTax: item.priceExTax,
        priceIncTax: item.price,
        taxRate: item.taxRate,
        qty: item.qty,
        liveItemId: item.liveMenuItem?._id ?? `nolive-${item.id}`,
        options: item.orderItemOptions.map<CustomerFacingExistingGmOrderItemOptionInternal>(
          (option) => ({
            id: option.id,
            priceExTax: option.priceExTax,
            priceIncTax: option.price,
            taxRate: option.taxRate,
            name: option.name,
            qty: option.qty,
          }),
        ),
      }))
    setOrderItemsInternal(mappedItemsInternal)
  }, [orderData])

  useEffect(() => {
    const mappedExternalItems = orderItemsInternal.map<CustomerFacingExistingGmOrderItem>(
      (item) => ({
        ...item,
        priceExTax: numberFormat(item.priceExTax / 100),
        priceIncTax: numberFormat(item.priceIncTax / 100),
        options: item.options.map<CustomerFacingExistingGmOrderItemOption>((option) => ({
          ...option,
          priceIncTax: numberFormat(option.priceIncTax / 100),
          priceExTax: numberFormat(option.priceExTax / 100),
        })),
      }),
    )
    setOrderItems(mappedExternalItems)
  }, [orderItemsInternal])

  const adjustItemQuantity = (input: { id: string; newQty: number }) => {
    setChangesMade(true)
    setOrderItemsInternal((prev) => {
      const newOrderItems = [...prev]
      const existingItem = orderData?.orderItems.items.find((item) => item.id === input.id)
      const hasChanges = existingItem?.qty !== input.newQty
      const indexOfItem = newOrderItems.findIndex((item) => item.id === input.id)
      if (indexOfItem >= 0) {
        newOrderItems[indexOfItem].qty = input.newQty
        newOrderItems[indexOfItem].hasChanges = hasChanges
        newOrderItems[indexOfItem].options = newOrderItems[indexOfItem].options.map((option) => ({
          ...option,
          qty: input.newQty,
        }))
      }
      return newOrderItems
    })
  }

  const addNewItemToOrder = (
    itemToAdd: Omit<
      CustomerFacingExistingGmOrderItemInternal,
      'id' | 'isExistingOrderItem' | 'hasChanges' | 'quantityAdjustable' | 'isCustom'
    >,
  ) => {
    setChangesMade(true)
    const temporaryId = createTemporaryId({
      itemCoreId: itemToAdd.liveItemId,
      options: itemToAdd.options.map((option) => ({ optionId: option.id, qty: option.qty })),
    })
    const existingMatchingItem = orderItemsInternal.find((item) => item.id === temporaryId)
    if (existingMatchingItem) {
      adjustItemQuantity({ id: temporaryId, newQty: existingMatchingItem.qty + 1 })
    } else {
      newItemCountState.inc()
      setTimeout(() => newItemCountState.dec(), 2000)
      setOrderItemsInternal((prev) => [
        ...prev,
        {
          id: temporaryId,
          isExistingOrderItem: false,
          hasChanges: true,
          quantityAdjustable: true,
          isCustom: false,
          ...itemToAdd,
        },
      ])
    }
  }

  const removeItemFromOrder = (input: { id: string }) => {
    setChangesMade(true)
    setOrderItemsInternal((prev) => {
      const newOrderItems = [...prev]
      const indexOfItem = newOrderItems.findIndex((item) => item.id === input.id)
      if (indexOfItem >= 0) {
        const removedItems = newOrderItems.splice(indexOfItem, 1)
        setRemovedOrderItems((prev) => [
          ...prev,
          ...removedItems.filter((item) => item.isExistingOrderItem),
        ])
      }
      return newOrderItems
    })
  }

  return {
    orderItems,
    orderItemsInternal,
    removedOrderItems,
    changesMade,
    itemQuantity,
    newItemWasJustAdded: newItemCount > 0,
    adjustItemQuantity,
    removeItemFromOrder,
    addNewItemToOrder,
  }
}
