'use client'

const optimisedDomains = [
  'teamfeedr.com',
  'feedr.co',
  'img.caterdesk-static.com',
  'img.teamfeedr.com',
]
export default function myImageLoader({ src, width, quality }) {
  let limitedWidth = width
  if (width > 1500) {
    limitedWidth = 1500
  }

  const params = ['format=auto', `width=${limitedWidth}`]
  if (quality) {
    params.push(`quality=${quality}`)
  }
  const paramsString = params.join(',')

  if (src.startsWith('https://')) {
    if (!optimisedDomains.some((domain) => src.includes(domain))) return src

    const url = new URL(src)
    url.pathname = `/cdn-cgi/image/${paramsString}${url.pathname}`
    return url.toString()
  }

  return src
}
