import styled from 'styled-components'

const PopoverBase = styled.li`
  padding: ${(p) => `${p.theme.spacings[2]}px ${p.theme.spacings[3]}px`};
  background-color: ${(p) => p.theme.colors.canvas};
`

export const PopoverWrapper = styled.ul`
  position: absolute;
  list-style: none;
  z-index: 100;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  margin: 46px 25px 0 0;
  border: 1px solid ${(p) => p.theme.colors.background};
  border-radius: 5px;
  box-shadow: 3px 3px 5px rgb(0 0 0 / 20%);
  max-height: 320px;
  overflow-y: scroll;
`

export const PopoverGroup = styled(PopoverBase)<{ useNewSavedAddresses: boolean }>`
  font-size: ${(p) => p.theme.fontSizes.base};
  font-weight: ${(p) =>
    p.useNewSavedAddresses ? p.theme.fontWeights.bold : p.theme.fontWeights.regular};
  color: ${(p) => (p.useNewSavedAddresses ? p.theme.colors.text_heading : p.theme.colors.primary)};
`

export const PopoverItem = styled(PopoverBase)`
  font-size: ${(p) => p.theme.fontSizes.xs};
  color: ${(p) => p.theme.colors.text_heading};
  & > p {
    margin: 0;
    margin-bottom: 5px;
    font-size: ${(p) => p.theme.fontSizes.s};
    font-weight: ${(p) => p.theme.fontWeights.semibold};
  }
  &:hover {
    background: ${(p) => p.theme.colors.background};
  }
`

export const PopoverDivider = styled(PopoverBase)`
  hr {
    margin: 0;
    border 0;
    border-top: 2px solid ${(p) => p.theme.colors.background_dark};
  }
`
