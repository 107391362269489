import React from 'react'
import QuantitySelect from '@/components/QuantitySelect'
import { Notes } from '@mui/icons-material'
import { Box, Card, Stack, Typography } from '@mui/material'
import { amountToPriceString, Currency } from '@teamfeedr/utils--money'
import { BasketCustomItemFragment } from '@/generated/graphql'

type Props = {
  currency: Currency
  taxAcronym: string
  item: BasketCustomItemFragment
}

const CustomItemCard: React.FC<Props> = ({ currency, taxAcronym, item }) => {
  return (
    <Card key={item.id}>
      <Stack padding={2} spacing={1} direction="column">
        <Box>
          <Typography>{item.name}</Typography>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <QuantitySelect quantity={item.quantity} disabled />
            <Typography variant="body2">
              {amountToPriceString(currency, item.customerPriceExTax * item.quantity, true)}
              &nbsp; (ex {taxAcronym})
            </Typography>
          </Stack>
        </Box>
        {item.notes && (
          <Stack spacing={1} direction="row">
            <Notes fontSize="small" />
            <Typography variant="caption">Notes: {item.notes}</Typography>
          </Stack>
        )}
      </Stack>
    </Card>
  )
}

export default CustomItemCard
