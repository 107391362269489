import { MenuVendorCategoryFragment, MenuVendorFragment } from '@/generated/graphql'
import { AppError } from '../utils/errors'
import { areFieldsNotNil, isNotNil, Replace, RequireFields } from '../utils/typing'

export type MenuVendorCategory = RequireFields<MenuVendorCategoryFragment, 'name'>
export type MenuVendor = RequireFields<
  Replace<MenuVendorFragment, { categories: MenuVendorCategory[] }>,
  'currency' | 'permalink'
>

type MenuVendorValidation =
  | { type: 'success'; vendor: MenuVendor }
  | { type: 'error'; error: AppError }

export const validateMenuVendor = ({
  currency,
  permalink,
  categories,
  isDraft,
  isGMActive,
  ...vendor
}: MenuVendorFragment): MenuVendorValidation => {
  if (isDraft || !isGMActive)
    return {
      type: 'error',
      error: { type: 'VENDOR_NOT_ACTIVE', vendorId: vendor.id },
    }

  if (!currency)
    return {
      type: 'error',
      error: { type: 'COULD_NOT_FETCH_VENDOR_CURRENCY', vendorId: vendor.id },
    }

  if (!permalink)
    return {
      type: 'error',
      error: { type: 'COULD_NOT_FETCH_VENDOR_PERMALINK', vendorId: vendor.id },
    }

  return {
    type: 'success',
    vendor: {
      ...vendor,
      isDraft,
      isGMActive,
      currency,
      permalink,
      categories: categories.filter(isNotNil).filter(areFieldsNotNil(['name'])),
    },
  }
}
