import React from 'react'
import { match } from 'ts-pattern'

import Celery from './celery'
import Cereals from './cereals'
import Crustaceans from './crustaceans'
import Eggs from './eggs'
import Fish from './fish'
import Halal from './halal'
import HighProtein from './highProtein'
import LowCarb from './lowCarb'
import Lupin from './lupin'
import Milk from './milk'
import Molluscs from './molluscs'
import Mustard from './mustard'
import NoGluten from './noGluten'
import NoMilk from './noMilk'
import None from './none'
import NoNuts from './noNuts'
import NoSugar from './noSugar'
import Nuts from './nuts'
import Peanuts from './peanuts'
import PlantPacked from './plantPacked'
import Sesame from './sesame'
import Soybeans from './soybeans'
import Sulphur from './sulphur'
import Vegan from './vegan'
import Vegetarian from './vegetarian'
import Pescatarian from './pescatarian'
import ExpertChoice from './expertChoice'
import LowCarbon from './lowCarbon'

const Icon: React.FC<{ type: string }> = ({ type }) => {
  return match(type)
    .with('celery', () => <Celery />)
    .with('cereals', () => <Cereals />)
    .with('crustaceans', () => <Crustaceans />)
    .with('eggs', () => <Eggs />)
    .with('fish', () => <Fish />)
    .with('halal', () => <Halal />)
    .with('highProtein', () => <HighProtein />)
    .with('lowCarb', () => <LowCarb />)
    .with('lupin', () => <Lupin />)
    .with('milk', () => <Milk />)
    .with('molluscs', () => <Molluscs />)
    .with('mustard', () => <Mustard />)
    .with('noGluten', () => <NoGluten />)
    .with('noMilk', () => <NoMilk />)
    .with('none', () => <None />)
    .with('noNuts', () => <NoNuts />)
    .with('noSugar', () => <NoSugar />)
    .with('nuts', () => <Nuts />)
    .with('peanuts', () => <Peanuts />)
    .with('plantPacked', () => <PlantPacked />)
    .with('sesame', () => <Sesame />)
    .with('soybeans', () => <Soybeans />)
    .with('sulphur', () => <Sulphur />)
    .with('vegan', () => <Vegan />)
    .with('vegetarian', () => <Vegetarian />)
    .with('pescatarian', () => <Pescatarian />)
    .with('expertChoice', () => <ExpertChoice />)
    .with('lowCarbon', () => <LowCarbon />)
    .with('veryLowCarbon', () => <LowCarbon />)
    .otherwise(() => <></>)
}

export default Icon
