import vatRegions from '@/generated/vatRegions.json'
import { CountryOptions } from './multiRegion/timezonesCitiesAndCountries'

export const taxAcronym = (countryCode?: string) =>
  vatRegions.find((vatRegion) => vatRegion.code === countryCode)?.taxAcronym || 'VAT'

export const getRegionsByCountryCode = (
  countryCode: string | undefined | null,
  countryOptions: CountryOptions[],
) => {
  const filteredCountryOptions = countryOptions.find(
    (countryOption) => countryOption.value === countryCode,
  )
  return filteredCountryOptions?.label
}
