import React from 'react'
import { match } from 'ts-pattern'
import Modal from '@teamfeedr/ui--modal'
import Box from '@teamfeedr/ui--box'
import { TextExtraSmall } from '@teamfeedr/ui--typography'
import { main } from '@teamfeedr/ui--theme'
import Stack from '@teamfeedr/ui--stack'
import { MenuVendor } from '../../domain/menu-vendor'
import { SectionTitle } from './index.styles'

type Props = {
  vendor: MenuVendor
}

export const SourcingTab: React.FC<Props> = ({ vendor }) => {
  const hasSourcingData = Boolean(vendor.sourcing)

  return (
    <Modal.Section>
      <Stack direction="vertical" spacing={16} alignItems="start">
        {match(hasSourcingData)
          .with(true, () => (
            <>
              <SectionTitle>All information provided by {vendor.companyName}</SectionTitle>

              <Box>
                <SectionTitle color={main.colors.text_heading}>Ingredient sources</SectionTitle>
                <Box padding={{ y: 4 }}>
                  <TextExtraSmall style={{ lineHeight: '24px' }}>{vendor.sourcing}</TextExtraSmall>
                </Box>
              </Box>
            </>
          ))
          .with(false, () => (
            <Box>
              <SectionTitle color={main.colors.text_heading}>
                There is no sourcing information yet
              </SectionTitle>
              <TextExtraSmall style={{ lineHeight: '24px' }}>
                We&apos;re waiting on information to be provided by {vendor.companyName}
              </TextExtraSmall>
            </Box>
          ))
          .exhaustive()}
      </Stack>
    </Modal.Section>
  )
}
