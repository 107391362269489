import React from 'react'

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 60">
      <path
        fillRule="evenodd"
        d="M40.027-6C18.197-6 .432 11.05.432 32s17.765 38 39.595 38 39.596-17.05 39.596-38S61.857-6 40.027-6zM26.08 4.996s1.127 3.876 2.694 4.744c0 0-1.128 1.735-1.128 4.744 0 .997.175 2.941.375 5.175.404 4.508.913 10.191.308 11.635-.904 2.16-4.723 7.535-10.105 7.323-5.404-.234-7.654-3.244-7.875-6.271-.22-2.99 1.329-5.771 3.358-8.14-.277.548-3.427 6.96-.2 9.03 3.356 2.141 9.195 1.697 12.129-.884 2.913-2.565-1.566-23.905-1.566-23.905s1.568-2.371 2.01-3.45zm19.708 5.586s.017-.185-.006-.253c-.07-.204-.48.67-2.61 10.941-2.841 13.68-18.09 17.095-18.09 17.095 8.5 1.292 14.607-2.779 16.782-7.045 2.177-4.28 3.686-13.266 3.686-13.266 0 12.844 1.164 16.791 4.427 18.276 3.264 1.504 9.586-.64 14.16-2.356 2.91-1.08 6.321-.631 6.321-.631l2.565-4.25s-3.654.406-6.27-.655c-.766-.315-1.623-.811-2.546-1.346-2.237-1.295-4.859-2.813-7.482-2.513-3.372.39-5.294 5.912-5.588 6.884.165-.352.81-1.254 2.984-2.388 2.818-1.504 8.481 1.701 8.481 1.701-2.6 2.353-7.413 2.564-11.323 2.14-3.566-.4-3.752-8.081-3.907-14.46v-.003c-.016-.645-.031-1.275-.05-1.883-.24-6.638-1.534-5.988-1.534-5.988zm-16.923 3.26s10.27 3.147 9.016 14.15c0 0-1.255-4.916-3.558-6.493-.781-.53-1.632-.835-2.424-1.119-1.564-.56-2.9-1.04-3.034-3-.201-2.955 0-3.538 0-3.538zm-8.849 28.306H17.71V51h2.3v-3.575h3.635V51h2.308s-.004-8.85 0-8.85h-2.308v3.47h-3.628v-3.47zm11.118 0h2.816L37.017 51h-2.532l-.555-1.948h-2.964l-.593 1.948h-2.3l3.06-8.85zm10.323 0h-2.326V51h6.18v-1.862h-3.854v-6.989zm9.094 0h2.823L56.43 51h-2.526l-.574-1.948h-2.965l-.573 1.948h-2.3l3.06-8.85zm11.246 0h-2.326V51h6.18v-1.862h-3.854v-6.989zm-29.393 2.004l-.992 3.29h2.081l-.98-3.29h-.109zm18.398 3.29l1-3.29h.128l.973 3.29h-2.1z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default Icon
